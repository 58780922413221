import React from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { FieldLabel } from "components/styled/Field";
import Fields from "components/ui/Fields";
import {
  onNodePoolArchitectureChange,
  onEdgeHostUpdate,
} from "state/cluster/actions/nodes";
import { getCluster } from "state/cluster/selectors/details";

import { getNicsByDeviceUid } from "state/cluster/selectors/create";
import { ARCHITECTURE_TYPES } from "utils/constants";
import { createDeviceListingSelection } from "components/common/NodePoolCloudFields/edge/Devices";
import DocLink from "components/common/DocLink";

const DevicesWrapper = styled.div`
  margin-top: 12px;
`;

export const DeviceSelection = createDeviceListingSelection({
  name: "nodepool",
});
const ConnectedDeviceSelection = connect(
  (state) => ({
    nicsByHost: getNicsByDeviceUid(state),
    value: state?.forms?.nodePool?.data?.edgeHosts || [],
    isOverlayEnabled:
      getCluster(state)?.spec?.cloudConfig?.spec?.clusterConfig
        ?.overlayNetworkConfiguration?.enable,
    extraItems: state?.forms?.nodePool?.initialData?.edgeHosts || [],
    architecture: state?.forms?.nodePool?.data?.architecture,
    initialArchitecture: state?.forms?.nodePool?.initialData?.architecture,
    hostClusterCloudType: getCluster(state)?.spec.cloudType,
  }),
  {
    onChange: onEdgeHostUpdate,
  }
)(DeviceSelection.Block);

function EdgeNativeConfigFields({
  onNodePoolArchitectureChange,
  nodePoolsWrapperRef,
  formData,
}) {
  const { t } = useTranslation();
  return (
    <>
      <Fields.Select
        name="architecture"
        label={t("Architecture")}
        data-qa="architecture"
        options={ARCHITECTURE_TYPES}
        onChange={onNodePoolArchitectureChange}
      />
      {formData.isControlPlane ? (
        <Fields.Switch
          name={"isTwoNode"}
          label={t("Enable 2 nodes capability")}
          data-qa={"isTwoNode"}
          disabled={true}
          helperText={
            <>
              {t(
                "This option provides essential redundancy with minimized resource use, ideal for ensuring reliability in smaller setups."
              )}
              <div>
                <DocLink location="two-node-control-plane" />
              </div>
            </>
          }
        />
      ) : null}
      <DevicesWrapper>
        <FieldLabel>{t("Nodes (edge hosts)")}</FieldLabel>
        <ConnectedDeviceSelection
          name="edgeHosts"
          nodePoolsWrapperRef={nodePoolsWrapperRef}
          isMasterPool={formData.isControlPlane}
          twoNodeEnabled={formData.isTwoNode}
        />
      </DevicesWrapper>
    </>
  );
}

export default connect(null, {
  onNodePoolArchitectureChange,
})(EdgeNativeConfigFields);
