import React from "react";
import isEmpty from "lodash/isEmpty";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { createSelector } from "reselect";

import Modal from "components/ui/Modal";
import ProfilesList from "modules/profileDifferentiator/components/ProfilesList";
import ClusterPackDiffsModal from "modules/profileDifferentiator/components/ClusterPackDiffsModal";

import ModalService from "services/modal";
import { profileDifferentiator } from "modules/profileDifferentiator/utils";
import Button, { TextButton } from "components/ui/Button";
import createFormActions from "modules/form/actions";
import store from "services/store";
import Validator from "services/validator";
import { isValidVariable } from "services/validator/rules";
import {
  getVariableDefaultValue,
  getVariableDetails,
  extractVariablesFromProfiles,
} from "utils/variablesHelpers";

export const PROFILE_VARIABLES_LAYER_ID = "profile-variables";

export function createProfileDiffModule({
  name,
  selectors,
  onReviewComplete,
  onLayerValuesChange,
  onVariablesValuesChange,
  reviewAdditions = false,
  ChangeSchema,
} = {}) {
  const { getTargetProfiles, getCurrentProfiles, getValues, getEntities } =
    selectors;

  const diffSummaryModal = new ModalService(`${name}-profileSummaryModal`);
  const diffReviewModal = new ModalService(`${name}-profileReviewModal`);
  const valuesFormModuleName = `${name}-values`;

  const getProfileModuleDifferentiator = createSelector(
    getTargetProfiles,
    getCurrentProfiles,
    getValues,
    getEntities,
    (targetProfiles, currentProfiles, values, entities) => {
      const diffManager = profileDifferentiator.getPackChanges({
        targetProfiles: targetProfiles || [],
        currentProfiles: currentProfiles || [],
        entities: entities || {},
        values: values || {},
        ...(ChangeSchema && { ChangeSchema }),
      });

      return diffManager;
    }
  );

  const getModuleState = createSelector(
    (state) => state.profileDifferentiator?.[name],
    (moduleState) => {
      return moduleState;
    }
  );

  const areModalOpened = createSelector(
    () => diffSummaryModal.isOpened(),
    () => diffReviewModal.isOpened(),
    (summaryOpened, reviewOpened) => summaryOpened || reviewOpened
  );

  const getSnapshot = createSelector(
    getModuleState,
    getProfileModuleDifferentiator,
    areModalOpened,
    (moduleState, differ, areModalOpened) => {
      if (!areModalOpened) {
        return differ;
      }
      return moduleState?.diffSnapshot;
    }
  );

  const getPacksChanges = createSelector(
    getSnapshot,
    (moduleDifferentiator) => {
      return moduleDifferentiator.denormalized;
    }
  );

  const getManifestsUnknownChanges = createSelector(
    getSnapshot,
    (moduleDifferentiator) => {
      return moduleDifferentiator.unknownManifests;
    }
  );

  const hasUnknownManifestChanges = createSelector(
    getManifestsUnknownChanges,
    (unknownManifests) => {
      return !isEmpty(unknownManifests);
    }
  );

  const getVariablesChanges = createSelector(
    getTargetProfiles,
    getCurrentProfiles,
    (targetProfiles, currentProfiles) => {
      return profileDifferentiator.getVariableChanges({
        targetProfiles: targetProfiles || [],
        currentProfiles: currentProfiles || [],
      });
    }
  );

  const getFilteredVariableChanges = createSelector(
    getVariablesChanges,
    (diffs) => {
      return diffs.filter((diff) =>
        diff.variableChanges.some((varDiff) => varDiff.type !== "no-change")
      );
    }
  );

  const getAllProfileChanges = createSelector(
    getSnapshot,
    getFilteredVariableChanges,
    (moduleDifferentiator, variableChanges) => {
      const profileChanges = (moduleDifferentiator.groupedChanges || []).map(
        (profileChange) => {
          const hasVariableChanges = variableChanges.some((diff) => {
            const profile = diff.target || diff.current;
            return (
              profile?.metadata?.name === profileChange?.profile?.metadata?.name
            );
          });

          return {
            ...profileChange,
            hasVariableChanges,
          };
        }
      );

      // Mimic the grouped changes structure for the variable changes if there are no profile changes
      const variableDiffs = variableChanges.reduce((accumulator, diff) => {
        const profile = diff?.target || diff?.current;
        const profileChange = profileChanges.find(
          (packDiff) =>
            packDiff?.profile?.metadata?.name === profile?.metadata?.name
        );

        if (!profileChange) {
          accumulator.push({
            profile,
            packs: [],
            hasVariableChanges: true,
          });
        }
        return accumulator;
      }, []);

      return [...profileChanges, ...variableDiffs];
    }
  );

  const getFilteredProfileChanges = createSelector(
    getSnapshot,
    (moduleDifferentiator) => {
      if (reviewAdditions) {
        return moduleDifferentiator.updatesAndAdditionsOnly;
      }
      return moduleDifferentiator.updatesOnly;
    }
  );

  const getVariableDiffsWithErrors = createSelector(
    getFilteredVariableChanges,
    (state) => state.forms?.[valuesFormModuleName]?.errors,
    (diffs, formErrors) => {
      const fieldsWithErrors = (formErrors || []).map(
        (error) => error.field.split(".")?.[1]
      );

      return diffs
        .filter((diff) => {
          const variablesGuids = (diff.variableChanges || []).map(
            (variableChange) =>
              variableChange?.target?.guid || variableChange?.current?.guid
          );
          return variablesGuids.some((variableGuid) =>
            fieldsWithErrors.includes(variableGuid)
          );
        })
        .map(({ target, current }) => {
          const profile = target || current;
          return profile?.metadata?.uid;
        });
    }
  );

  function toggleExpandedProfiles(profileGuids) {
    return function thunk(dispatch) {
      dispatch({
        type: "DIFF_VIEWER_UPDATE_EXPANDED",
        profileGuids,
        module: name,
      });
    };
  }

  function toggleExpandedVariablesDiffs(profileGuids) {
    return function thunk(dispatch) {
      dispatch({
        type: "DIFF_VIEWER_VARIABLES_UPDATE_EXPANDED",
        profileGuids,
        module: name,
      });
    };
  }

  const validator = new Validator();
  validator.addRule("variables", function* (value, key, data) {
    const profiles = getTargetProfiles(store.getState());
    const profileVariables = extractVariablesFromProfiles(profiles);

    for (const key of Object.keys(data.variables)) {
      yield {
        field: `variables.${key}.value`,
        result: isValidVariable({
          ...getVariableDetails(data.variables[key], profileVariables),
        })(data.variables[key]),
      };
    }
  });

  const valuesFormActions = createFormActions({
    validator,
    async init() {
      const profileDiffs = getFilteredProfileChanges(store.getState());
      const variableDiffs = getFilteredVariableChanges(store.getState());
      const packChanges = (profileDiffs || []).flatMap(
        (profile) => profile.packs
      );

      const packChangesValues = packChanges.reduce((acc, change) => {
        const manifestChanges = change.manifests || [];
        const manifestValues = manifestChanges.reduce((acc, change) => {
          return {
            ...acc,
            [change.target.manifest.guid]: change.target.values || "",
          };
        }, {});

        return {
          ...acc,
          ...manifestValues,
          [change.target.pack.guid]: change.target.values || "",
        };
      }, {});

      const variableValues = variableDiffs.reduce(
        (
          accumulator,
          { target: targetProfile, current: currentProfile, variableChanges }
        ) => {
          const profile = targetProfile || currentProfile;
          const values = variableChanges.reduce(
            (acc, { current, target }) => ({
              ...acc,
              ...(target && {
                [target.guid]: {
                  profileName: profile?.metadata?.name,
                  profileUid: profile?.metadata?.uid,
                  name: target.name,
                  value: getVariableDefaultValue(target),
                  guid: target.guid,
                },
              }),
              ...(current && {
                [current.guid]: {
                  profileName: profile?.metadata?.name,
                  profileUid: profile?.metadata?.uid,
                  name: current.name,
                  value: getVariableDefaultValue(current),
                  guid: current.guid,
                  skipValidation: true,
                },
              }),
            }),
            {}
          );
          return { ...accumulator, ...values };
        },
        {}
      );

      return { values: packChangesValues, variables: variableValues };
    },
  });

  function onSelectDiffViewerLayer(layerGuid, profileGuid) {
    return function thunk(dispatch) {
      dispatch({
        type: "DIFF_VIEWER_SELECT_LAYER",
        layerGuid,
        profileGuid,
        module: name,
      });
    };
  }

  function onSelectProfileVariablesLayer() {
    return function thunk(dispatch) {
      dispatch({
        type: "DIFF_VIEWER_SELECT_VARIABLES_LAYER",
        layerGuid: PROFILE_VARIABLES_LAYER_ID,
        module: name,
      });
    };
  }

  function onValuesChange(values) {
    return function thunk(dispatch, getState) {
      const selectedLayerGuid = getModuleState(getState())?.selectedLayer;
      if (onLayerValuesChange) {
        return dispatch(onLayerValuesChange(selectedLayerGuid, values));
      }
    };
  }

  function toggleLayers({ profilesDiffs, isSummary = false }) {
    return function thunk(dispatch) {
      if (isSummary) {
        const profileGuids = profilesDiffs.map(
          (profileDiff) => profileDiff?.profile?.guid
        );
        return dispatch(toggleExpandedProfiles(profileGuids));
      }

      const { profile, packs } = profilesDiffs?.[0] || {};
      const { target, current } = packs?.[0] || {};
      const packGuid = target?.pack?.guid || current?.pack?.guid;

      dispatch(toggleExpandedProfiles([profile?.guid]));
      dispatch(onSelectDiffViewerLayer(packGuid, profile?.guid));
    };
  }

  function openDiffSummaryModal(...args) {
    return function thunk(dispatch, getState) {
      const differentiator = getProfileModuleDifferentiator(getState());
      dispatch({
        type: "RESET_REVIEWED_VARIABLE_LAYERS",
        module: name,
      });

      dispatch({
        type: "DIFF_VIEWER_SET_DIF_SNAPSHOT",
        snapshot: differentiator.clone(),
        module: name,
      });

      diffSummaryModal.open(...args).then((modalData) => {
        const profilesDiffs = getFilteredProfileChanges(getState());
        const variableDiffs = getFilteredVariableChanges(getState());
        const hasVariableDiffs = !isEmpty(variableDiffs);

        if (!profilesDiffs.length && !hasVariableDiffs) {
          if (onReviewComplete) {
            return dispatch(onReviewComplete(modalData));
          }
          return;
        }
        dispatch(toggleLayers({ profilesDiffs }));
        dispatch(valuesFormActions.init({ module: valuesFormModuleName }));

        if (!profilesDiffs.length && hasVariableDiffs) {
          dispatch(onSelectProfileVariablesLayer());
        }

        return diffReviewModal.open(...args).then(async (modalData) => {
          if (onReviewComplete) {
            const values =
              getState().forms?.[valuesFormModuleName]?.data?.values || {};
            const variables =
              getState().forms?.[valuesFormModuleName]?.data?.variables || {};

            if (hasVariableDiffs) {
              const variableErrors = await dispatch(
                valuesFormActions.validateForm({ module: valuesFormModuleName })
              );

              if (variableErrors.length) {
                return Promise.reject();
              }
            }

            if (onLayerValuesChange) {
              Object.keys(values).forEach((layerGuid) => {
                dispatch(onLayerValuesChange(layerGuid, values[layerGuid]));
              });
            }

            if (onVariablesValuesChange && hasVariableDiffs) {
              dispatch(onVariablesValuesChange(variables));
            }

            return dispatch(onReviewComplete(modalData));
          }
        });
      });

      const profilesDiffs = getAllProfileChanges(getState());
      dispatch(toggleLayers({ profilesDiffs, isSummary: true }));
    };
  }

  const ConnectedProfileList = connect(
    (state) => ({
      profilesDiffs: getAllProfileChanges(state),
      expanded: getModuleState(state)?.expanded,
    }),
    {
      onSelectDiffViewerLayer,
      toggleExpandedProfiles,
    }
  )(ProfilesList);

  function ProfileDiffsSummaryModal({
    withReviewStep,
    title,
    canUpdateLater = false,
    packsUpdatesMessages = {},
  }) {
    const { t } = useTranslation();
    const confirmLabel = withReviewStep
      ? t("Review changes in Editor")
      : t("Apply Changes");

    function renderFooter() {
      return (
        <div>
          <TextButton
            data-qa="cancel-action"
            onClick={() => {
              diffSummaryModal.reject();
            }}
          >
            {t("Cancel")}
          </TextButton>
          <Button
            data-qa="apply-changes"
            onClick={() => diffSummaryModal.resolve()}
          >
            {confirmLabel}
          </Button>
          {canUpdateLater && !withReviewStep && (
            <Button
              data-qa="update-profile-later"
              onClick={() => diffSummaryModal.resolve({ updateLater: true })}
            >
              {t("Apply Changes Later")}
            </Button>
          )}
        </div>
      );
    }

    return (
      <Modal
        width="600px"
        title={title || t("Changes Summary")}
        service={diffSummaryModal}
        footer={renderFooter()}
      >
        <span>Upcoming changes in the new profile configuration:</span>
        <ConnectedProfileList
          withSummary={true}
          packsUpdatesMessages={packsUpdatesMessages}
        />
      </Modal>
    );
  }

  const ConnectedProfileDiffsSummaryModal = connect((state) => ({
    withReviewStep:
      !isEmpty(getFilteredProfileChanges(state)) ||
      !isEmpty(getFilteredVariableChanges(state)),
  }))(ProfileDiffsSummaryModal);

  const ProfileDiffsReviewModal = connect(
    (state) => ({
      selectedLayerGuid: getModuleState(state)?.selectedLayer,
      selectedProfileGuid: getModuleState(state)?.selectedProfile,
      profilesDiffs: getFilteredProfileChanges(state),
      expanded: getModuleState(state)?.expanded,
      modalService: diffReviewModal,
      modalOpened: diffReviewModal.isOpened(),
      valuesForm: {
        actions: valuesFormActions,
        name: valuesFormModuleName,
      },
      variablesData: {
        expanded: getModuleState(state)?.expandedVariableLayers,
        reviewedLayers: getModuleState(state)?.reviewedVariableLayers,
        variableChanges: getFilteredVariableChanges(state),
        hasVariableChanges: !isEmpty(getFilteredVariableChanges(state)),
        diffsWithErrors: getVariableDiffsWithErrors(state),
      },
    }),
    {
      onSelectDiffViewerLayer,
      toggleExpandedProfiles,
      toggleExpandedVariablesDiffs,
      onValuesChange,
      onSelectProfileVariablesLayer,
    }
  )(ClusterPackDiffsModal);

  return {
    selectors: {
      getSnapshot,
      getPacksChanges,
      getManifestsUnknownChanges,
      hasUnknownManifestChanges,
      getAllProfileChanges,
      getFilteredProfileChanges,
      getModuleState,
      getFilteredVariableChanges,
    },
    actions: {
      openDiffSummaryModal,
      onSelectProfileVariablesLayer,
    },
    services: {
      diffSummaryModal,
      diffReviewModal,
    },
    Block({
      isSubmitting = false,
      isRepaveRequired = false,
      readOnly = false,
      cloudType,
      title,
      canUpdateLater = false,
      packsUpdatesMessages = {},
      originalValues = {},
    }) {
      return (
        <>
          <ConnectedProfileDiffsSummaryModal
            title={title}
            canUpdateLater={canUpdateLater}
            packsUpdatesMessages={packsUpdatesMessages}
          />
          <ProfileDiffsReviewModal
            isSubmitting={isSubmitting}
            isRepaveRequired={isRepaveRequired}
            readOnly={readOnly}
            title={title}
            cloudType={cloudType}
            canUpdateLater={canUpdateLater}
            originalValues={originalValues}
          />
        </>
      );
    },
  };
}
