export const DOC_BASE_URL = "https://docs.spectrocloud.com" as const;
export const DOC_LINK_MAPPING = {
  clusters: "/clusters/",
  "public-cloud": "/clusters/public-cloud/",
  "data-center": "/clusters/data-center/",
  edge: "/clusters/edge/",
  "cluster-management": "/clusters/cluster-management/",
  "deploy-cluster": "/clusters/public-cloud/deploy-k8s-cluster",
  "cluster-profiles": "/cluster-profiles/",
  terraform: "/terraform",
  "imported-clusters": "/clusters/imported-clusters/",
  "node-pool-repave":
    "/clusters/cluster-management/node-pool/#repave-behavior-and-configuration",
  "full-cluster-repave":
    "/clusters/cluster-management/node-pool/#repave-behavior-and-configuration",
  "default-resource-limits": "/user-management/palette-resource-limits/",
  repavement: "/glossary-all/#repavement",
  eks: "/clusters/public-cloud/aws/architecture/#aws-eks-architecture",
  "aws-eks": "/clusters/public-cloud/aws/eks/",
  "cluster-management-labels": "/clusters/cluster-management/node-labels",
  "cluster-management-taints": "/clusters/cluster-management/taints",
  "add-helm-registry":
    "/registries-and-packs/helm-charts/#add-a-helm-chart-registry-to-palette",
  "edge-overlay": "/clusters/edge/networking/vxlan-overlay ",
  "kms-key": "/clusters/public-cloud/aws/enable-secrets-encryption-kms-key",
  "eks-cluster-endpoint":
    "https://docs.aws.amazon.com/eks/latest/userguide/cluster-endpoint.html",
  "aws-instance-types-and-pod-capacity":
    "/clusters/public-cloud/aws/architecture/#aws-instance-type-and-pod-capacity",
  "node-pool": "/clusters/cluster-management/node-pool",
  "fargate-profiles":
    "https://docs.aws.amazon.com/eks/latest/userguide/fargate.html",
  "cluster-profile-versioning":
    "/profiles/cluster-profiles/modify-cluster-profiles/version-cluster-profile/",
  "profile-variables":
    "/profiles/cluster-profiles/create-cluster-profiles/define-profile-variables",
  "backup-restore": "/clusters/cluster-management/backup-restore",
  "oidc-userinfo": "/user-management/saml-sso/palette-sso-with-okta",
  "two-node-control-plane": "/tbd", // TODO: @codrin-iftimie update this link
} as const;
