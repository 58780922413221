import store from "services/store";
import i18n from "i18next";
import debounce from "redux-debounce-thunk";
import moment from "moment";
import axios from "axios";

import Validator from "services/validator";
import createFormActions from "modules/form/actions";

import {
  extractNodePoolFieldsFromYAML,
  populateNodePoolYAML,
  updateNodePoolNameInYAML,
  updateNodePoolSizeInYAML,
} from "utils/domain/nodepools";
import {
  Missing,
  isKubernetesName,
  MaxLength,
  areValidKeyValueK8sLabels,
  ApplyIf,
  isValidIP,
  areValidIPTags,
} from "services/validator/rules";
import {
  NodePoolSchema,
  CloudConfigSchema,
  ClusterProfileSchema,
  PackVersionSchema,
  ClusterProfileTemplateSchema,
} from "utils/schemas";
import {
  ARCHITECTURE_TYPES,
  BAREMETAL_ENVS,
  EDGE_NATIVE,
  SPECTRO_TAG,
  UPDATE_STRATEGIES,
} from "utils/constants";

import { round } from "utils/number";

import {
  getNodePools,
  getClusterCloudConfig,
  getSelectedNodePool,
  getSubnetsForSelectedAz,
  getAzureAzs,
  isStaticPlacementEnabled,
  getAzureInstanceTypes,
  getSystemNodePools,
  getAllNodes,
  getAllEdgeHosts,
} from "state/cluster/selectors/nodes";
import {
  getCluster,
  getClusterImport,
  getClusterProfileTemplate,
  hasAutoscalePack,
  isBrownfield,
} from "state/cluster/selectors/details";
import { getClusterByUid } from "./details";

import {
  pollHybridPoolsProfileUpdates,
  pollNodes,
  watchHybridPools,
} from "utils/tasks";
import api from "services/api";
import ModalService from "services/modal";
import notifications from "services/notifications";
import i18next from "i18next";

import { utilizationFetcher, hostClusterFetcher } from "state/cluster/services";
import {
  nodePoolAppliancesFetcher,
  nodePoolApplianceResourceFetchers,
  nodePoolApplianceNetworksKeyedFetcher,
  azureInstanceTypesFetcher,
  azureAZFetcher,
  azureStorageAccountsFetcher,
  gcpAZFetcher,
  gcpInstanceTypesFetcher,
  nodeDetailsModalService,
  NODE_DETAILS_FILTERS_MODULE,
  DEFAULT_RESOURCE_POOL_VALUE,
  confirmNodePoolSizeModal,
  addNodePoolModal,
  maintenanceModeModal,
} from "state/cluster/services/nodes";
import {
  datacentersFetcher,
  propertiesFetcher,
  ipamFetcher,
  appliancesKeyedFetcher,
  vsphereAppliancesFetcher,
  eksVolumeFetcher,
  cloudTemplateFetchers,
} from "state/cluster/services/create";
import { dnsMappingsFetcher } from "state/dns/services";
import { createOpenstackFormFactory } from "modules/cluster/openstack";
import { createMaasFormFactory } from "modules/cluster/maas";
import { createTencentFormFactory } from "modules/cluster/tencent";
import cloneDeep from "lodash/cloneDeep";
import { mapCloudType } from "utils/presenters";
import {
  getPackValuesWithoutPresetsComment,
  parseLabelsForInput,
} from "utils/parsers";
import {
  COX_CALICO_INBOUND_RULES,
  getNicsByDeviceUid,
  getNodeCommonPoolConfig,
} from "../selectors/create";
import { fetchNodesConfigParams, volumeValidator } from "./create/flows/cox";
import { isStaticIpInUse, isHostNameInUse } from "./create/form";
import { cleanEmptyKeys } from "utils/objects";
import {
  edgeHostDetailsFetcher,
  edgeMachinesFetcher,
} from "../services/create/flows/edge";
import appEnv from "services/app";
import { getConfigMacros, GET_DEFAULT_NODE_VALUES } from "./create/common";
import isEqual from "fast-deep-equal";
import { profileDifferentiator } from "modules/profileDifferentiator/utils";
import { buildTargetProfiles, UPDATE_TYPES } from "./updates";
import { getDemoFlags } from "services/flags";
import { FLAGS } from "utils/constants/flags";

const commonValidator = new Validator();
commonValidator.addRule(["poolName", "size"], Missing());
commonValidator.addRule("size", (size, _, nodePool) => {
  const cloudType = getCluster(store.getState())?.spec?.cloudType;
  if (cloudType === "edge-native" && size >= 1) {
    return false;
  }

  if (nodePool.isControlPlane && size % 2 === 0) {
    return i18n.t("Control Plane node pool size should be an odd number");
  }

  return false;
});
commonValidator.addRule(["poolName"], isKubernetesName());
commonValidator.addRule(["poolName"], MaxLength(63));
commonValidator.addRule("poolName", (poolName) => {
  const selectedNodePool = getSelectedNodePool(store.getState());
  const nodePools = getNodePools(store.getState());

  if (selectedNodePool) {
    return false;
  }
  if (nodePools?.find((nodePool) => nodePool.name === poolName)) {
    return i18n.t("Node pool name already exists");
  }
  return false;
});

export const openstackNodesForm = createOpenstackFormFactory(
  {
    getCloudAccountUid(state) {
      return getClusterCloudConfig(state)?.spec?.cloudAccountRef?.uid;
    },
    getClusterConfig(state) {
      const config = getClusterCloudConfig(state)?.spec?.clusterConfig;

      return {
        domain: config?.domain?.name,
        region: config?.region,
        project: config?.project?.name,
        staticPlacement: !!config?.network?.name,
      };
    },
  },
  { isNodes: true }
);

export const maasNodesForm = createMaasFormFactory(
  {
    getCloudAccountUid(state) {
      return getClusterCloudConfig(state)?.spec?.cloudAccountRef?.uid;
    },
  },
  { isNodes: true }
);

const awsNodePoolValidator = new Validator();
awsNodePoolValidator.addRule(["instanceType", "azs"], Missing());
awsNodePoolValidator.addRule("maxPricePercentage", (value, key, data) => {
  if (data.instanceOption === "onSpot") {
    return Missing()(value, key, data);
  }

  return false;
});
awsNodePoolValidator.addRule(function* (nodePool) {
  const staticPlacement = getSubnetsForSelectedAz(store.getState());
  for (const az of nodePool?.azs || []) {
    yield {
      result:
        staticPlacement && !nodePool[`subnet_${az}`]
          ? i18next.t("Missing subnet for selected az")
          : false,
      field: `subnet_${az}`,
    };
  }
});

const eksNodePoolValidator = new Validator();
eksNodePoolValidator.addRule(["instanceType"], Missing());
eksNodePoolValidator.addRule(["azs"], (value, key, data) => {
  const clusterConfig =
    getClusterCloudConfig(store.getState())?.spec?.clusterConfig || {};

  if (clusterConfig.vpcid || clusterConfig.vpcId) {
    return Missing()(value, key, data);
  }
  return false;
});
eksNodePoolValidator.addRule(function* (nodePool) {
  const staticPlacement = getSubnetsForSelectedAz(store.getState());
  for (const az of nodePool?.azs || []) {
    yield {
      result:
        staticPlacement && !nodePool[`subnet_${az}`]
          ? i18next.t("Missing subnet for selected az")
          : false,
      field: `subnet_${az}`,
    };
  }
});
eksNodePoolValidator.addRule(["disk"], async (value, key, data) => {
  const cloudConfig = getClusterCloudConfig(store.getState());
  if (!data?.amiId) {
    return false;
  }
  try {
    const { sizeGB } = await api.get(
      `v1/clouds/aws/imageIds/${data?.amiId}/volumeSize`,
      {
        region: cloudConfig.spec.clusterConfig.region,
        cloudAccountUid: cloudConfig.spec.cloudAccountRef.uid,
      }
    );

    return value < sizeGB ? "Disk size should be greater than AMI size" : false;
  } catch (err) {
    return false;
  }
});
export const rootVolumeValidator = new Validator();
rootVolumeValidator.addRule(["throughput"], (value, key, data) => {
  if (data.iops && data.type === "gp3") {
    const max = Math.min(0.25 * data.iops, 1000);
    if (value > max) {
      return `Throughput must be less than (${max})`;
    }
  }

  return false;
});
eksNodePoolValidator.addRule(["rootVolume"], rootVolumeValidator);

const openstackNodePoolValidator = new Validator();
openstackNodePoolValidator.addRule(["flavor", "disk", "azs"], Missing());

const vsphereNodePoolValidator = new Validator();
vsphereNodePoolValidator.addRule(["memory", "disk", "cpu"], Missing());

const domainValidator = new Validator();
domainValidator.addRule(
  ["cluster", "datastore", "network"],
  Missing({ message: () => " " })
);
domainValidator.addRule(["parentPoolUid"], (...args) => {
  const useStaticIp = isStaticPlacementEnabled(store.getState());
  if (!useStaticIp) {
    return false;
  }

  return Missing({ message: () => " " })(...args);
});

domainValidator.addRule("cluster", function* (value, key, data) {
  const domains = store.getState().forms.nodePool.data.domains;
  const clusters = domains.map(({ cluster }) => cluster);

  for (const clusterIndex in clusters) {
    const cluster = clusters[clusterIndex];
    const duplicates = clusters.filter(
      (currentItem) => currentItem === cluster
    );
    yield {
      result:
        duplicates.length > 1 ? i18next.t("Clusters must be unique") : false,
      field: `domains.${clusterIndex}.cluster`,
    };
  }
});

vsphereNodePoolValidator.addRule("domains", domainValidator);
vsphereNodePoolValidator.addRule("domains", function* (value, key, data) {
  for (const domainIndex in value) {
    const domain = value[domainIndex];
    yield {
      result:
        data.isControlPlane &&
        data.domains
          .map((domain) => domain.network)
          .some((network) => network !== "" && network !== domain.network)
          ? i18n.t("Control Plane nodes must share the same network")
          : false,
      field: `domains.${domainIndex}.network`,
    };
  }
});

const azurePoolValidator = new Validator();
azurePoolValidator.addRule(
  ["disk", "instanceType", "storageAccountType"],
  Missing()
);

azurePoolValidator.addRule("azs", (value, key, data) => {
  if (data.isMaster || data.isControlPlane) {
    return false;
  }

  const kind = getClusterCloudConfig(store.getState())?.kind;
  if (kind === "aks") {
    return false;
  }

  if (!getAzureAzs(store.getState()).length) {
    return false;
  }

  return Missing()(value, key, data);
});

azurePoolValidator.addRule("isSystemNodePool", (value, key, data) => {
  const kind = getClusterCloudConfig(store.getState())?.kind;

  if (kind === "aks") {
    const nodePools = getNodePools(store.getState());
    const nodePoolsAsSystemPool = (nodePools || []).filter(
      (nodePool) => nodePool.isSystemNodePool
    );

    if (nodePoolsAsSystemPool.length > 1) {
      return false;
    }

    if (
      nodePoolsAsSystemPool.length === 1 &&
      nodePoolsAsSystemPool[0].name === data.poolName
    ) {
      return value
        ? false
        : i18n.t("At least one pool must be set to be system");
    }
  }

  return false;
});

const googleNodePoolValidator = new Validator();
googleNodePoolValidator.addRule(["instanceType", "azs"], Missing());

const maasNodePoolValidator = new Validator();
maasNodePoolValidator.addRule(["minCPU", "minMem", "resourcePool"], Missing());

const taintsValidator = new Validator();
taintsValidator.addRule(["key", "value", "effect"], Missing());

const tencentNodePoolValidator = new Validator();
tencentNodePoolValidator.addRule(["instanceType"], Missing());
tencentNodePoolValidator.addRule(["azs"], (value, key, data) => {
  const clusterConfig =
    getClusterCloudConfig(store.getState())?.spec?.clusterConfig || {};
  if (clusterConfig.vpcid || clusterConfig.vpcID) {
    return Missing()(value, key, data);
  }
  return false;
});

const edgeNativeNodePoolValidator = new Validator();
const edgeHostsValidator = new Validator();
edgeHostsValidator.addRule(["hostUid"], Missing());
edgeHostsValidator.addRule(["hostName"], isHostNameInUse(getAllEdgeHosts));
edgeHostsValidator.addRule(
  ["nicName"],
  ApplyIf((value, key, data) => !!data?.nicName, Missing())
);
edgeHostsValidator.addRule(
  ["ip", "gateway", "subnet", "dns"],
  ApplyIf((value, key, data) => data.enableStaticIp, Missing())
);
edgeHostsValidator.addRule(
  ["ip", "gateway", "subnet"],
  ApplyIf((value, key, data) => data.enableStaticIp, isValidIP())
);
edgeHostsValidator.addRule(
  ["ip"],
  ApplyIf(
    (value, key, data) => data.enableStaticIp,
    isStaticIpInUse(getAllEdgeHosts)
  )
);
edgeHostsValidator.addRule(
  ["dns"],
  ApplyIf((value, key, data) => data.enableStaticIp, areValidIPTags())
);

edgeNativeNodePoolValidator.addRule(["edgeHosts"], edgeHostsValidator);

const coxEdgeNodePoolValidator = new Validator();
const deploymentValidator = new Validator();
deploymentValidator.addRule(["name", "pops"], Missing({ message: () => " " }));
coxEdgeNodePoolValidator.addRule(["deployments"], deploymentValidator);
coxEdgeNodePoolValidator.addRule(["instanceType"], Missing());
coxEdgeNodePoolValidator.addRule(["persistentStorages"], volumeValidator);
coxEdgeNodePoolValidator.addRule(["persistentStorages"], (value) => {
  if (value.length > 7) {
    return i18next.t("A maximum of 7 volumes is allowed");
  }

  return false;
});

const VALIDATOR_MAPPING = {
  aws: awsNodePoolValidator,
  eks: eksNodePoolValidator,
  vsphere: vsphereNodePoolValidator,
  azure: azurePoolValidator,
  aks: azurePoolValidator,
  gcp: googleNodePoolValidator,
  maas: maasNodePoolValidator,
  openstack: openstackNodePoolValidator,
  tke: tencentNodePoolValidator,
  "edge-native": edgeNativeNodePoolValidator,
  coxedge: coxEdgeNodePoolValidator,
};
const nodePoolValidator = new Validator();
nodePoolValidator.addRule(
  "edgeHosts",
  Missing({
    message: () => i18n.t("The node pool should contain at least 1 Edge Host"),
  })
);
nodePoolValidator.addRule(
  "edgeHosts",
  ApplyIf(
    (_1, _2, data) => data.isControlPlane,
    (value, key, data) => {
      const isTwoNodeEdgeEnabled = data.isTwoNode;

      if (isTwoNodeEdgeEnabled) {
        return {
          result:
            value.length !== 2
              ? i18n.t(
                  "The Control Plane node should contain exactly 2 Edge Hosts"
                )
              : false,
        };
      }

      return [1, 3, 5].includes(value.length)
        ? false
        : {
            result: i18n.t(
              "The Control Plane node should contain 1, 3 or 5 Edge Hosts"
            ),
          };
    }
  )
);
nodePoolValidator.addRule(function* () {
  yield commonValidator;
  const clusterConfig = getClusterCloudConfig(store.getState());
  const kind = clusterConfig.kind || clusterConfig.metadata.kind;

  if (VALIDATOR_MAPPING[kind]) {
    yield VALIDATOR_MAPPING[kind];
  }
});

nodePoolValidator.addRule(["taints"], function* (value, key, data) {
  if (data.withTaints) {
    yield taintsValidator;
  }
});

nodePoolValidator.addRule(["additionalLabels"], function (value) {
  for (const item of value) {
    const [key, val] = item.split(":");
    if (val === SPECTRO_TAG || !key || !val) {
      return i18next.t(`Label "{{item}}" is invalid`, { item });
    }
  }
  const keys = value.reduce((acc, item) => {
    if (item.includes(":")) {
      const [key] = item.split(":");
      acc.push(key);
      return acc;
    }
    acc.push(item);
    return acc;
  }, []);
  const set = new Set(keys);
  return keys.length !== set.size
    ? i18next.t(`Duplicate keys are not allowed`)
    : areValidKeyValueK8sLabels()(value);
});

nodePoolValidator.addRule(["nodeConfigMacros"], function* (macrosObj) {
  const fields = Object.keys(macrosObj).filter(
    (key) => !macrosObj[key].isOptional
  );

  for (const field of fields) {
    yield {
      result: Missing()(macrosObj[field].value, field, macrosObj),
      field: `nodeConfigMacros.${field}.value`,
    };
  }
});

function getCommonPayload(data) {
  return {
    ...getNodeCommonPoolConfig({
      ...data,
      isMaster: data.isControlPlane,
      maxNodeSize: data.maxSize,
      minNodeSize: data.minSize,
    }),
  };
}

function formatEdgeHosts(edgeHosts = [], isMasterPool = false) {
  const isTwoNodeMode = edgeHosts.length === 2 && isMasterPool;

  return edgeHosts.map((host, index) => {
    const {
      dns = [],
      gateway = "",
      ip = "",
      isDefault = false,
      macAddr = "",
      nicName = "",
      subnet = "",
      enableStaticIp,
      ...rest
    } = host;

    let twoNodeMode = {};

    if (isTwoNodeMode) {
      twoNodeMode = {
        twoNodeCandidatePriority: "primary",
      };

      if (index === 1) {
        twoNodeMode = {
          twoNodeCandidatePriority: "secondary",
        };
      }
    }

    const hasNicInfo = nicName && nicName !== "auto";

    return {
      ...twoNodeMode,
      ...rest,
      ...(hasNicInfo
        ? {
            nic: {
              nicName,
              ...(enableStaticIp
                ? { dns, gateway, ip, isDefault, macAddr, subnet }
                : {}),
            },
          }
        : {}),
    };
  });
}

const PAYLOAD_MAPPING = {
  aws(data) {
    return {
      cloudConfig: {
        instanceType: data.instanceType,
        capacityType: data.instanceOption === "onSpot" ? "spot" : "on-demand",
        spotMarketOptions:
          data.instanceOption === "onSpot"
            ? {
                maxPrice: `${(
                  (data.instancePrice * data.maxPricePercentage) /
                  100
                ).toLocaleString(undefined, {
                  maximumFractionDigits: 5,
                })}`,
              }
            : undefined,
        azs: data.azs,
        additionalSecurityGroups: (data?.additionalSecurityGroups || []).map(
          (id) => ({ id })
        ),
        rootDeviceSize: data?.disk,
        subnets: data.azs.reduce((acc, az) => {
          const subnetIsSet = data[`subnet_${az}`];
          const id = Array.isArray(subnetIsSet)
            ? subnetIsSet.join(",")
            : subnetIsSet;

          if (subnetIsSet) {
            acc.push({ az, id });
          }
          return acc;
        }, []),
      },
      poolConfig: {
        ...getCommonPayload(data),
      },
    };
  },
  eks(data) {
    return {
      cloudConfig: {
        instanceType: data.instanceType,
        azs: data.azs,
        rootDeviceSize: data?.disk,
        capacityType: data.instanceOption === "onSpot" ? "spot" : "on-demand",
        subnets: (data?.azs || []).reduce((acc, az) => {
          const subnetIsSet = data[`subnet_${az}`];
          const id = Array.isArray(subnetIsSet)
            ? subnetIsSet.join(",")
            : subnetIsSet;

          if (subnetIsSet) {
            acc.push({ az, id });
          }
          return acc;
        }, []),
        ...(data.launchTemplateEnabled
          ? {
              awsLaunchTemplate: {
                ami: { id: data.amiId },
                rootVolume: cleanEmptyKeys(data.rootVolume),
              },
            }
          : {}),
      },
      poolConfig: {
        ...getCommonPayload(data),
      },
    };
  },
  vsphere(data) {
    const useStaticIp = isStaticPlacementEnabled(store.getState());
    return {
      cloudConfig: {
        instanceType: {
          diskGiB: data?.disk,
          memoryMiB: data?.memory * 1024,
          numCPUs: data?.cpu,
        },
        placements: (data?.domains || []).map(
          ({ network, parentPoolUid, dns, resourcePool, ...rest }) => ({
            ...rest,
            resourcePool:
              resourcePool === DEFAULT_RESOURCE_POOL_VALUE ? "" : resourcePool,
            network: {
              networkName: network,
              staticIp: useStaticIp,
              parentPoolUid,
            },
          })
        ),
      },
      poolConfig: {
        ...getCommonPayload(data),
      },
    };
  },
  azure(data) {
    return {
      cloudConfig: {
        instanceType: data.instanceType,
        azs: data.azs,
        osDisk: {
          diskSizeGB: data.disk,
          managedDisk: {
            storageAccountType: data.storageAccountType,
          },
          osType: data.osType,
        },
      },
      poolConfig: {
        ...getCommonPayload(data),
      },
    };
  },

  aks(data) {
    return {
      managedPoolConfig: {
        isSystemNodePool: data.isSystemNodePool,
        osType: data.isSystemNodePool ? "Linux" : data.osType,
      },
      cloudConfig: {
        instanceType: data.instanceType,
        azs: data.azs,
        osDisk: {
          diskSizeGB: data.disk,
          managedDisk: {
            storageAccountType: data.storageAccountType,
          },
        },
      },
      poolConfig: {
        ...getCommonPayload(data),
      },
    };
  },
  gcp(data) {
    return {
      cloudConfig: {
        instanceType: data.instanceType,
        azs: data.azs,
        rootDeviceSize: data.disk,
      },
      poolConfig: {
        ...getCommonPayload(data),
      },
    };
  },
  gke(data) {
    return {
      cloudConfig: {
        instanceType: data.instanceType,
        rootDeviceSize: data.disk,
      },
      poolConfig: {
        ...getCommonPayload(data),
      },
    };
  },
  maas(data) {
    return {
      cloudConfig: {
        instanceType: {
          minCPU: data.minCPU,
          minMemInMB: data.minMem * 1024,
        },
        azs: data.azs,
        resourcePool: data?.resourcePool,
        tags: data.tags,
      },
      poolConfig: {
        ...getCommonPayload(data),
      },
    };
  },
  openstack(data) {
    const selectors = openstackNodesForm.selectors;
    const state = store.getState();
    const subnets = selectors.getOpenstackNetworkSubnets(state);
    const flavorsData =
      openstackNodesForm.fetchers.flavorsFetcher.selector(state);
    const flavorConfig = (flavorsData.result || []).find(
      (flavor) => flavor.name === data.flavor
    );
    const subnet = subnets.find((subnet) => subnet.id === data.subnet);

    return {
      cloudConfig: {
        flavorConfig: flavorConfig
          ? {
              numCPUs: flavorConfig.vcpus,
              memoryMiB: flavorConfig.memory,
              name: flavorConfig.name,
              diskGiB: flavorConfig.disk,
            }
          : undefined,
        azs: data.azs,
        diskGiB: data.disk,
        subnet,
      },
      poolConfig: {
        ...getCommonPayload(data),
      },
    };
  },
  edge(data) {
    return {
      cloudConfig: {
        edgeHosts: data.edgeHosts,
      },
      poolConfig: {
        ...getCommonPayload(data),
        maxSize: undefined,
        minSize: undefined,
      },
    };
  },
  "edge-native": (data) => ({
    cloudConfig: {
      edgeHosts: formatEdgeHosts(data.edgeHosts, data.isControlPlane),
    },
    poolConfig: {
      ...getCommonPayload(data),
      maxSize: undefined,
      minSize: undefined,
    },
  }),
  "eks-edge-native": (data) => {
    return {
      clusterConfig: {
        ntpServers: data.ntpServers,
      },
      cloudConfig: {
        machineCloudConfig: {
          archType: data.architecture,
          edgeHosts: formatEdgeHosts(data.edgeHosts, data.isControlPlane),
        },
        archType: data.architecture,
        edgeHosts: formatEdgeHosts(data.edgeHosts, data.isControlPlane),
        clusterConfig: {
          ntpServers: data.ntpServers,
        },
        profiles: data.profiles.map((profile) => ({
          uid: profile.metadata.uid,
          packValues: profile.spec.published.packs.map((pack) => {
            if (pack.pack) {
              return {
                tag: pack.tag,
                version: pack.pack.spec.version,
                type: pack.pack.spec.type,
                values: pack.values,
                name: pack.pack.metadata.name,
              };
            }

            return {
              tag: pack.tag,
              version: pack.version,
              type: pack.type,
              values: pack.values,
              name: pack.metadata?.name || pack.name,
            };
          }),
        })),
      },
      poolConfig: {
        ...getCommonPayload(data),
        maxSize: undefined,
        minSize: undefined,
      },
    };
  },
  libvirt(data) {
    return {
      cloudConfig: {
        instanceType: {
          numCPUs: data.cpu,
          memoryInMB: data.memory * 1024,
          cpuset: data?.cpuset,
          cpuPassthroughSpec: data.cpuPassthroughSpec,
          gpuConfig: data.gpuSupport
            ? {
                numGPUs: data.numGPUs,
                vendorName: data.gpuVendor,
                deviceModel: data.gpuModel,
              }
            : undefined,
        },
        placements: data.edgeHosts.map((edgeHost) => ({
          hostUid: edgeHost.hostUid,
          dataStoragePool: edgeHost.dataStoragePool,
          sourceStoragePool: edgeHost.sourceStoragePool,
          targetStoragePool: edgeHost.targetStoragePool,
          networks: (edgeHost?.networks || []).map((network) => ({
            networkName: network,
            networkType: edgeHost.networkType,
          })),
        })),
        rootDiskInGB: data.disk,
        nonRootDisksInGB: (data?.nonRootDisks || []).map((diskSize) => ({
          sizeInGB: parseInt(diskSize),
          managed: data?.persistentNonRootDisks,
        })),
      },
      poolConfig: {
        ...getCommonPayload(data),
        maxSize: undefined,
        minSize: undefined,
      },
    };
  },
  tke(data) {
    return {
      cloudConfig: {
        instanceType: data.instanceType,
        azs: data.azs,
        rootDeviceSize: data?.disk,
        subnetIds: (data.azs || []).reduce((acc, az) => {
          const azSubnets = data[`subnet_${az}`];
          const id = Array.isArray(azSubnets) ? azSubnets.join(",") : azSubnets;

          if (id) {
            return { ...acc, [az]: id };
          }
          return acc;
        }, {}),
      },
      poolConfig: {
        ...getCommonPayload(data),
        maxSize: undefined,
        minSize: undefined,
        size: data.size,
      },
    };
  },
  coxedge(data) {
    const inboundRules = data.network?.inboundRules || [];
    const outboundRules = data.network?.outboundRules || [];

    return {
      cloudConfig: {
        spec: data.instanceType,
        deployments: (data?.deployments || []).map(
          ({
            enableAutoScaling,
            minInstancesPerPop,
            maxInstancesPerPop,
            instancesPerPop,
            ...deployment
          }) => ({
            ...deployment,
            pops: [deployment.pops],
            ...(enableAutoScaling
              ? {
                  minInstancesPerPop,
                  maxInstancesPerPop,
                }
              : {
                  instancesPerPop,
                }),
          })
        ),
        persistentStorages: data.persistentStorages,
        securityGroupRules: [...inboundRules, ...outboundRules].map((rule) => ({
          ...rule,
        })),
      },
      poolConfig: {
        ...getCommonPayload(data),
        useControlPlaneAsWorker: !!data?.useControlPlaneAsWorker,
      },
    };
  },
};

export function fetchClusterCloudConfig() {
  return async function thunk(dispatch, getState) {
    const cluster = getCluster(getState());
    const { cloudType, cloudConfigRef } = cluster.spec;
    const type = cloudType === "all" ? "generic" : mapCloudType(cloudType);

    if (!cloudConfigRef) {
      return Promise.resolve(null);
    }

    const isCustomCloud = appEnv.isCustomCloud(type);
    const clusterCloudConfigPromise = api.get(
      isCustomCloud
        ? `v1/cloudconfigs/cloudTypes/${type}/${cloudConfigRef.uid}`
        : `v1/cloudconfigs/${type}/${cloudConfigRef.uid}`
    );

    dispatch({
      promise: clusterCloudConfigPromise,
      type: "FETCH_CLUSTER_CLOUD_CONFIG",
      schema: CloudConfigSchema,
    });

    try {
      await clusterCloudConfigPromise;
      const config = getCluster(getState()).spec.cloudConfig;
      const hybridPools = (config.spec.hybridMachinePools || []).map(
        (nodePool) => {
          return dispatch({
            type: "FETCH_HYBRID_MACHINE_POOL",
            promise: api
              .get(
                `v1/cloudconfigs/aws/${config.metadata.uid}/edge-native/machinePools/${nodePool.poolName}`
              )
              .then((nodePool) => ({
                ...nodePool,
                uid: `${config.metadata.uid}_${nodePool.poolConfig.name}`,
              })),
            schema: NodePoolSchema,
          });
        }
      );
      await Promise.allSettled(hybridPools);
    } catch (error) {
      if (axios.isCancel(error)) {
        return;
      }
      notifications.error({
        message: i18n.t(
          "Something went wrong when getting the cluster cloud config"
        ),
        description: error?.message,
      });
      return;
    }

    pollHybridPoolsProfileUpdates.start();
    return clusterCloudConfigPromise;
  };
}

export function fetchAwsCloudConfigParams({
  type = null,
  isSupervized = false,
} = {}) {
  return async function thunk(dispatch, getState) {
    const state = getState();
    let region, cloudAccountUid, vpcid;

    if (type === "create") {
      region = state.forms.cluster.data?.region;
      cloudAccountUid = state.forms.cluster.data?.credential;
      vpcid = state?.forms?.cluster?.data?.vpcid;
    } else {
      const clusterCloudConfig = getClusterCloudConfig(getState());
      region = clusterCloudConfig.spec.clusterConfig.region;
      vpcid = clusterCloudConfig?.spec?.clusterConfig?.vpcId;
      cloudAccountUid = clusterCloudConfig.spec.cloudAccountRef.uid;
    }

    const promise = Promise.allSettled(
      [
        api.get(
          `v1/clouds/aws/regions/${region}/availabilityzones?cloudAccountUid=${cloudAccountUid}`
        ),
        api.get(
          `v1/clouds/aws/regions/${region}/instancetypes?cloudAccountUid=${cloudAccountUid}`
        ),
        api.get(
          `v1/clouds/aws/regions/${region}/vpcs?cloudAccountUid=${cloudAccountUid}`
        ),
        vpcid &&
          !isSupervized &&
          api.get(
            `v1/clouds/aws/securitygroups?cloudAccountUid=${cloudAccountUid}&region=${region}&vpcId=${vpcid}`
          ),
        isSupervized && dispatch(eksVolumeFetcher.key(region).fetch()),
      ].filter(Boolean)
    ).then((responses) => {
      const results = responses.reduce((acc, curr) => {
        const error =
          curr.status !== "fulfilled"
            ? acc.error + ` ${curr?.reason?.message}. `
            : "";

        return {
          ...acc,
          ...curr.value,
          error,
        };
      }, {});

      if (results.error) {
        notifications.error({
          message: i18n.t("Something went wrong while fetching data"),
          description: results.error || "",
        });
      }

      return {
        azs: results?.zones || [],
        instanceTypes: results?.instanceTypes || [],
        vpcids: results?.vpcs || [],
        additionalSecurityGroups: results?.groups || [],
      };
    });

    dispatch({
      promise,
      type: "FETCH_CLOUD_CONFIG_PARAMS",
    });

    return await promise;
  };
}

function sendNodePoolSize(nodePoolGuid) {
  return async (dispatch, getState) => {
    const nodePool = getNodePools(getState()).find(
      (nodePool) => nodePool.guid === nodePoolGuid
    );
    const cluster = getCluster(getState());
    const clusterCloudConfig = getClusterCloudConfig(getState());

    const desiredNodePoolSize =
      getState().cluster.nodes.desiredNodePoolSizes[nodePool.name];

    let promise;

    const kind = clusterCloudConfig?.kind || clusterCloudConfig?.metadata?.kind;

    const formData = (await POPULATE_FIELDS_MAPPING?.[kind]?.(nodePool)) || {
      ...nodePool,
      ...getInitialFormDataCommon(nodePool),
    };

    const cloudType = mapCloudType(cluster.spec.cloudType);

    let payload = PAYLOAD_MAPPING?.[kind]?.(formData);
    const isCustomCloud = appEnv.isCustomCloud(cloudType);
    if (isCustomCloud) {
      payload = {
        poolConfig: {
          ...getCommonPayload(formData),
        },
        cloudConfig: {
          values: updateNodePoolSizeInYAML(
            formData.values,
            desiredNodePoolSize
          ),
        },
      };
    }

    payload.poolConfig.size = desiredNodePoolSize;

    const apiPrefix = isCustomCloud
      ? "v1/cloudconfigs/cloudTypes"
      : "v1/cloudconfigs";
    if (!nodePool.persisted) {
      promise = api.post(
        `${apiPrefix}/${cloudType}/${clusterCloudConfig.metadata.uid}/machinePools`,
        payload
      );
    } else {
      promise = api.put(
        `${apiPrefix}/${cloudType}/${clusterCloudConfig.metadata.uid}/machinePools/${nodePool.name}`,
        payload
      );
    }

    dispatch({
      promise,
      type: "UPDATE_CLUSTER",
    });

    try {
      await promise;
      notifications.info({
        message: i18n.t("Cluster update will begin shortly"),
      });
    } catch (error) {
      const message = nodePool.persisted
        ? i18n.t("Something went wrong when editing the node pool")
        : i18n.t("Something went wrong when creating the node pool");

      dispatch({
        type: "UPDATE_DESIRED_NODE_POOL_SIZE",
        nodePoolName: nodePool.name,
        size: nodePool.size,
      });

      if (axios.isCancel(error)) {
        return;
      }

      notifications.error({
        message,
        description: error?.message,
      });
      return;
    }

    await dispatch(fetchClusterCloudConfig());
    await dispatch(fetchClusterNodes());
    dispatch(fetchClusterEstimatedRate());
    pollNodes.start();
  };
}

function setNodePoolToConfigure(nodePoolGuid) {
  return (dispatch) => {
    dispatch({
      type: "SET_NODEPOOL_TO_CONFIGURE",
      nodePoolToConfigureGuid: nodePoolGuid,
    });
  };
}

export function fetchAzureNodeParams() {
  return function (dispatch) {
    dispatch(azureInstanceTypesFetcher.fetch());
    dispatch(azureAZFetcher.fetch());
    dispatch(azureStorageAccountsFetcher.fetch());
  };
}

export function fetchGoogleCloudNodeParams() {
  return function (dispatch) {
    dispatch(gcpAZFetcher.fetch());
    dispatch(gcpInstanceTypesFetcher.fetch());
  };
}

export function fetchMaasCloudNodeParams(maasModule) {
  return function (dispatch) {
    dispatch(maasModule.fetchers.resourcePoolsFetcher.fetch());
    dispatch(maasModule.fetchers.azsFetcher.fetch());
    dispatch(maasModule.fetchers.tagsFetcher.fetch());
  };
}

function fetchVmWareNodeParams() {
  return async function (dispatch, getState) {
    await dispatch(datacentersFetcher.fetch());
    const useStaticIps = isStaticPlacementEnabled(getState());
    if (useStaticIps) {
      dispatch(ipamFetcher.fetch());
    }
  };
}

export function fetchBaremetalParams(isEdit = false) {
  return async (dispatch, getState) => {
    await dispatch(edgeMachinesFetcher.fetch(isEdit));

    if (isEdit) {
      dispatch(nodePoolAppliancesFetcher.fetch());

      (getState().forms?.nodePool?.data?.edgeHosts || []).forEach(
        (_, index) => {
          nodePoolApplianceResourceFetchers.forEach((fetcher) =>
            dispatch(fetcher.key(`${index}`).fetch())
          );
        }
      );
    } else {
      const clusterCreateNodePools =
        getState().forms?.cluster?.data?.nodePools || [];
      clusterCreateNodePools.forEach((_, index) => {
        dispatch(appliancesKeyedFetcher.key(`${index}`).fetch());
      });
    }
  };
}

export function changeNodePoolSize(nodePoolGuid, size, maxNodes) {
  return (dispatch, getState) => {
    if (maxNodes && size > maxNodes) {
      return;
    }
    const nodePool = getNodePools(getState()).find(
      (nodePool) => nodePool.guid === nodePoolGuid
    );
    dispatch({
      type: "UPDATE_DESIRED_NODE_POOL_SIZE",
      nodePoolName: nodePool.name,
      size,
    });
  };
}

export function updateNodePoolSize(nodePoolGuid) {
  return async (dispatch, getState) => {
    const nodePoolToUpdate = getNodePools(getState()).find(
      (nodePool) => nodePool.guid === nodePoolGuid
    );

    const temporaryNodePoolSize =
      getState().cluster.nodes.temporaryNodePoolSizes[nodePoolToUpdate.name];

    if (!temporaryNodePoolSize) {
      dispatch({
        type: "UPDATE_DESIRED_NODE_POOL_SIZE",
        nodePoolName: nodePoolToUpdate.name,
        size: nodePoolToUpdate.size,
      });

      return;
    }

    if (nodePoolToUpdate.size !== temporaryNodePoolSize) {
      confirmNodePoolSizeModal.open().then(
        () => {
          dispatch({
            type: "UPDATE_NODE_POOL_SIZE",
            nodePoolName: nodePoolToUpdate.name,
          });
          dispatch(sendNodePoolSize(nodePoolGuid));
        },
        () => {
          dispatch({
            type: "UPDATE_DESIRED_NODE_POOL_SIZE",
            nodePoolName: nodePoolToUpdate.name,
            size: nodePoolToUpdate.size,
          });
        }
      );

      const clusterCloudConfig = getClusterCloudConfig(getState());
      if (clusterCloudConfig.kind === "openstack") {
        await dispatch(loadNodePoolCloudProperties());
      }
      dispatch(estimateRatePerNodePool(nodePoolGuid, temporaryNodePoolSize));
    }
  };
}

export const onNodePoolSizeChange = (size) => {
  return (dispatch, getState) => {
    dispatch(
      addNodePoolFormActions.onChange({
        module: "nodePool",
        name: "size",
        value: size,
      })
    );
    const cloudType = getClusterCloudConfig(getState())?.metadata?.kind;
    const isCustom = appEnv.isCustomCloud(cloudType);
    if (isCustom) {
      const currentValues = getState().forms.nodePool.data.values;
      const updatedValues = updateNodePoolSizeInYAML(currentValues, size);

      dispatch(
        addNodePoolFormActions.onChange({
          module: "nodePool",
          name: "values",
          value: updatedValues,
        })
      );
    }
    if (cloudType === EDGE_NATIVE.apiKey) {
      const edgeHosts = getState().forms.nodePool.data.edgeHosts;
      dispatch(
        addNodePoolFormActions.onChange({
          module: "nodePool",
          name: "edgeHosts",
          value: Array.from({ length: size }, (_, index) => ({
            hostUid: "",
            hostName: "",
            ...(edgeHosts[index] || {}),
          })),
        })
      );
    }
  };
};

export const onNodePoolNameChange = (value) => {
  return (dispatch, getState) => {
    dispatch(
      addNodePoolFormActions.onChange({
        module: "nodePool",
        name: "poolName",
        value: value,
      })
    );

    const currentValues = getState().forms.nodePool.data.values;
    const updatedValues = updateNodePoolNameInYAML(currentValues, value);

    dispatch(
      addNodePoolFormActions.onChange({
        module: "nodePool",
        name: "values",
        value: updatedValues,
      })
    );
  };
};

export const onNodePoolValuesChange = (values) => {
  return (dispatch, getState) => {
    dispatch(
      addNodePoolFormActions.onChange({
        module: "nodePool",
        name: `values`,
        value: values,
      })
    );

    const updatedFields = extractNodePoolFieldsFromYAML(values);
    Object.keys(updatedFields).forEach((field) => {
      const value = updatedFields[field];
      if (typeof value !== "undefined") {
        dispatch(
          addNodePoolFormActions.onChange({
            module: "nodePool",
            name: field,
            value,
          })
        );
      }
    });
  };
};

export function fetchClusterNodes() {
  return async (dispatch, getState) => {
    const state = getState();
    let nodePools = getNodePools(state);
    let clusterCloudConfig = getClusterCloudConfig(state);
    const cluster = getClusterImport(state);

    if (cluster?.isBrownfield || !nodePools.length) {
      await dispatch(fetchClusterCloudConfig());
      nodePools = getNodePools(state);
      clusterCloudConfig = getClusterCloudConfig(state);
    }

    const clusterType =
      clusterCloudConfig?.kind || clusterCloudConfig?.metadata?.kind;
    const cloudType =
      clusterType === "all" ? "generic" : mapCloudType(clusterType);

    const isCustomCloud = appEnv.isCustomCloud(cloudType);

    const promises = nodePools.map((nodePool) => {
      if (!nodePool.name) {
        return Promise.resolve({ ...nodePool, nodes: [] });
      }
      let endpoint = `v1/cloudconfigs/${cloudType}/${clusterCloudConfig.metadata.uid}/machinePools/${nodePool.name}/machines`;
      if (isCustomCloud) {
        endpoint = `v1/cloudconfigs/cloudTypes/${cloudType}/${clusterCloudConfig.metadata.uid}/machinePools/${nodePool.name}/machines`;
      }

      if (
        nodePool.isHybrid &&
        nodePool.cloudConfig?.hybridClusterRef?.cloudConfigUid
      ) {
        endpoint = `v1/cloudconfigs/${nodePool.poolCloudType}/${nodePool.cloudConfig.hybridClusterRef.cloudConfigUid}/machinePools/${nodePool.name}/machines`;
      }

      const promise = api.get(endpoint);

      dispatch({
        type: "FETCH_CLUSTER_NODES",
        nodePool: nodePool.guid,
        promise: promise.then((res) => {
          if (!res.items) {
            return { ...nodePool, nodes: [] };
          }

          if (nodePool.minSize && nodePool.maxSize) {
            const activeNodes = res.items.filter((node) => {
              const status = node?.spec?.phase || node?.status?.instanceState;

              return [
                "running",
                "provisioning",
                "provisioned",
                "unknown",
              ].includes(status?.toLowerCase?.());
            });
            dispatch({
              type: "UPDATE_DESIRED_NODE_POOL_SIZE",
              nodePoolName: nodePool.name,
              size: activeNodes.length,
            });
          }

          return {
            ...nodePool,
            nodes: res.items,
          };
        }),
        schema: NodePoolSchema,
      });

      return promise;
    });

    watchHybridPools.start();

    return Promise.all(promises);
  };
}

function getNodeMetrics() {
  return function thunk(dispatch, getState) {
    const time = getState().forms[NODE_DETAILS_FILTERS_MODULE]?.data?.dateTime;
    const nodeUid = nodeDetailsModalService.data?.nodeUid;

    const periods = {
      "1 hours": 10,
      "6 hours": 30,
      "12 hours": 60,
      "24 hours": 60,
      "1 weeks": 1440,
      "1 months": 1440,
    };

    const query = {
      startTime: moment()
        .subtract(...time.split(" "))
        .utc()
        .format(),
      endTime: moment().utc().format(),
      period: periods[time],
    };

    dispatch(utilizationFetcher.fetch(nodeUid, query, "machine"));
  };
}

export function onTimerangeChange(value) {
  return async (dispatch) => {
    await dispatch(
      utilizationMetricsFormActions.onChange({
        module: NODE_DETAILS_FILTERS_MODULE,
        name: "dateTime",
        value,
      })
    );
    dispatch(getNodeMetrics());
  };
}

export const utilizationMetricsFormActions = createFormActions({
  init() {
    return Promise.resolve({
      utilizationType: "cpu",
      dateTime: "1 hours",
    });
  },
});

export function onUtilizationTypeChange(value) {
  return (dispatch) => {
    dispatch(
      utilizationMetricsFormActions.onChange({
        module: NODE_DETAILS_FILTERS_MODULE,
        name: "utilizationType",
        value,
      })
    );
  };
}

export async function openNodeDetailsModal({ nodeUid, isMaster }) {
  if (!nodeUid) {
    return;
  }
  nodeDetailsModalService.open({ nodeUid, isMaster });
  await store.dispatch(
    utilizationMetricsFormActions.init({
      module: NODE_DETAILS_FILTERS_MODULE,
    })
  );
  store.dispatch(getNodeMetrics(nodeUid));
}

export function openMaintenanceModeModal(data) {
  maintenanceModeModal.open().then(async () => {
    const { cloudConfig } = getCluster(store.getState()).spec;

    const cloudType = cloudConfig?.kind || cloudConfig?.metadata?.kind;
    const configUid = cloudConfig.metadata.uid;
    const machinePoolName = data?.metadata?.name;
    const machineUid = data?.metadata?.uid;
    const maintenanceAction = data?.status?.maintenanceStatus?.action || "";

    const action = maintenanceAction === "cordon" ? "uncordon" : "cordon";

    try {
      await api.put(
        `v1/cloudconfigs/${cloudType}/${configUid}/machinePools/${machinePoolName}/machines/${machineUid}/maintenance`,
        { action }
      );
    } catch (err) {
      notifications.error({
        message: i18next.t(
          "Something went wrong when trying to activate Maintenance mode"
        ),
        description: err?.message,
      });
    }
  });
}

export function fetchClusterAndNodes(uid) {
  return async (dispatch, getState) => {
    let cluster = getCluster(getState());

    if (!cluster || cluster.metadata.uid !== uid) {
      await dispatch(getClusterByUid(uid));
      cluster = getCluster(getState());
    }

    const isDevMode = getState().auth.devMode;
    if (cluster?.spec?.cloudType === "nested" && !isDevMode) {
      await dispatch(hostClusterFetcher.fetch());
    }

    const nodesFetcher = dispatch(fetchClusterNodes());

    dispatch({
      type: "GETTING_CLUSTER_NODES",
      promise: nodesFetcher,
    });

    return nodesFetcher;
  };
}

function getUpdateStrategyValue(data) {
  return data?.isControlPlane
    ? UPDATE_STRATEGIES[1].value
    : data?.updateStrategy?.type || UPDATE_STRATEGIES[0].value;
}

function getInitialFormDataCommon(data) {
  const isAutoscalerEnabled =
    !data?.isControlPlane && !!(data?.minSize && data?.maxSize);
  const architecture =
    data?.machinePoolProperties?.archType || ARCHITECTURE_TYPES[0].value;

  return {
    poolName: data?.name || "new-worker-pool",
    size: data?.size || 1,
    minSize: data?.minSize || 1,
    maxSize: data?.maxSize || 3,
    isControlPlane: data?.isControlPlane || false,
    useControlPlaneAsWorker: data?.useControlPlaneAsWorker,
    taints: data?.taints || [],
    nodeRepaveInterval: data?.nodeRepaveInterval || 0,
    ...(data?.machinePoolProperties?.archType
      ? {
          architecture,
        }
      : {}),
    additionalLabels: parseLabelsForInput(data?.additionalLabels) || [],
    updateStrategy: getUpdateStrategyValue(data),
    isAutoscalerEnabled,
  };
}

function getSecurityGroupRules(type, data) {
  return (data?.securityGroupRules || [])
    .map((securityRule) => ({
      ...securityRule,
      protocol: (securityRule?.protocol || "").toUpperCase(),
    }))
    .filter((rule) => rule.type === type);
}

function getDeployments(data) {
  if (!data?.deployments) {
    return [{ name: "", pops: "", instancesPerPop: 1 }];
  }

  return (data.deployments || []).map((deployment) => ({
    ...deployment,
    pops: deployment?.pops?.[0],
  }));
}

function formatHostsData(hostData) {
  return (hostData || []).map(({ nic, ...rest }) => ({
    ...rest,
    ...nic,
    ...(nic.ip ? { enableStaticIp: true } : { enableStaticIp: false }),
  }));
}

const POPULATE_FIELDS_MAPPING = {
  async aws(data) {
    let subnets = {};
    if (data?.subnetIds) {
      subnets = Object.keys(data.subnetIds).reduce((acc, key) => {
        const value = data.subnetIds[key] ? data.subnetIds[key].split(",") : [];
        acc[`subnet_${key}`] = value.filter(Boolean);
        return acc;
      }, {});
    }

    const { instanceTypes } = await store.dispatch(fetchAwsCloudConfigParams());

    const instancePrice = data?.instanceType
      ? instanceTypes?.find(({ type }) => type === data?.instanceType)?.price
      : undefined;

    const maxPrice = parseFloat(data?.spotMarketOptions?.maxPrice) || undefined;

    (data?.azs || []).forEach((az) => {
      subnets[`subnet_${az}`] = subnets[`subnet_${az}`] || "";
    });
    const additionalSecurityGroups = (data?.additionalSecurityGroups || []).map(
      (item) => item.id
    );

    return {
      ...getInitialFormDataCommon(data),
      disk: data?.rootDeviceSize || 60,
      azs: data?.azs || [],
      additionalSecurityGroups,
      instanceType: data?.instanceType || "",
      instanceOption: data?.capacityType === "spot" ? "onSpot" : "onDemand",
      instancePrice,
      maxPricePercentage:
        instancePrice && maxPrice
          ? round((100 / instancePrice) * maxPrice, 3)
          : undefined,
      ...subnets,
      rootVolume: data?.rootVolume || {},
    };
  },
  async eks(data) {
    let subnets = {};
    if (data?.subnetIds) {
      subnets = Object.keys(data.subnetIds).reduce((acc, key) => {
        const value = data.subnetIds[key] ? data.subnetIds[key].split(",") : [];
        acc[`subnet_${key}`] = value;
        return acc;
      }, {});
    }
    (data?.azs || []).forEach((az) => {
      subnets[`subnet_${az}`] = subnets[`subnet_${az}`] || "";
    });
    await store.dispatch(
      fetchAwsCloudConfigParams({ type: "edit", isSupervized: true })
    );

    return {
      ...getInitialFormDataCommon(data),
      disk: data?.rootDeviceSize || 60,
      azs: data?.azs || [],
      instanceType: data?.instanceType || "",
      instanceOption: data?.capacityType === "spot" ? "onSpot" : "onDemand",
      amiId: data?.awsLaunchTemplate?.ami?.id || "",
      launchTemplateEnabled: !!(
        data?.awsLaunchTemplate?.ami?.id ||
        Object.keys(data?.awsLaunchTemplate?.rootVolume || {}).length
      ),
      rootVolume: data?.awsLaunchTemplate?.rootVolume,
      ...subnets,
    };
  },
  vsphere(data) {
    const useStaticIp = isStaticPlacementEnabled(store.getState());
    let domains = (data?.placements || []).map((placement) => ({
      ...placement,
      disabled: true,
      network: placement.network.networkName,
      resourcePool: !!placement.resourcePool
        ? placement.resourcePool
        : DEFAULT_RESOURCE_POOL_VALUE,
      parentPoolUid: placement.network.parentPoolRef?.uid,
      staticIp: useStaticIp,
    }));

    if (domains.length === 0) {
      domains = [
        {
          cluster: "",
          resourcePool: "",
          datastore: "",
          network: "",
          staticIp: useStaticIp,
          parentPoolUid: "",
        },
      ];
    }
    const defaultNodeValues = GET_DEFAULT_NODE_VALUES();

    return {
      ...getInitialFormDataCommon(data),
      disk: data?.instanceType?.diskGiB || defaultNodeValues.disk,
      memory: data?.instanceType?.memoryMiB
        ? data?.instanceType?.memoryMiB / 1024
        : defaultNodeValues.memory,
      cpu: data?.instanceType?.numCPUs || defaultNodeValues.minCPU,
      domains,
    };
  },
  azure(data) {
    return {
      ...getInitialFormDataCommon(data),
      azs: data?.azs || [],
      instanceType: data?.instanceType,
      disk: data?.osDisk?.diskSizeGB || 60,
      osType: data?.osDisk.osType || "Linux",
      storageAccountType: data?.osDisk?.managedDisk?.storageAccountType,
    };
  },
  aks(data) {
    return {
      ...getInitialFormDataCommon(data),
      azs: data?.azs || [],
      instanceType: data?.instanceType,
      disk: data?.osDisk?.diskSizeGB || 60,
      storageAccountType: data?.osDisk?.managedDisk?.storageAccountType,
      isSystemNodePool: data?.isSystemNodePool || false,
      osType: data?.osType || "Linux",
    };
  },
  gcp(data) {
    return {
      ...getInitialFormDataCommon(data),
      disk: data?.rootDeviceSize || 60,
      azs: data?.azs || [],
      instanceType: data?.instanceType || "",
    };
  },
  gke(data) {
    return {
      ...getInitialFormDataCommon(data),
      size: data?.size || 3,
      minSize: data?.minSize || 3,
      disk: data?.rootDeviceSize || 60,
      azs: data?.azs || [],
      instanceType: data?.instanceType || "",
    };
  },
  maas(data) {
    const defaultNodeValues = GET_DEFAULT_NODE_VALUES();

    return {
      ...getInitialFormDataCommon(data),
      minCPU: data?.instanceType?.minCPU || defaultNodeValues.minCPU,
      minMem: data?.instanceType?.minMemInMB
        ? data?.instanceType?.minMemInMB / 1024
        : defaultNodeValues.minMem,
      azs: data?.azs || defaultNodeValues.azs,
      resourcePool: data?.resourcePool || defaultNodeValues.resourcePool,
      tags: data?.tags || [],
    };
  },
  openstack(data) {
    return {
      ...getInitialFormDataCommon(data),
      size: data?.size || 1,
      azs: data?.azs || [],
      disk: data?.diskGiB || 60,
      flavor: data?.flavorConfig?.name || "",
      subnet: data?.subnet?.name || "",
    };
  },
  edge(data) {
    return {
      ...getInitialFormDataCommon(data),
      disk: data?.diskGiB || 60,
      edgeHosts: [...cloneDeep(data?.hosts || [{ hostUid: "" }])],
    };
  },
  "edge-native": (data) => ({
    ...getInitialFormDataCommon(data),
    architecture:
      data?.machinePoolProperties?.archType || ARCHITECTURE_TYPES[0].value,
    size: data?.hosts?.length || 1,
    disk: data?.diskGiB || 60,
    edgeHosts: formatHostsData(data?.hosts),
  }),
  "eks-edge-native": async (data) => {
    let profiles = [];
    let clusterConfig = {};
    if (data?.cloudConfig?.hybridClusterRef?.uid) {
      const cluster = await api.get(
        `v1/spectroclusters/${data.cloudConfig.hybridClusterRef.uid}`
      );
      const packInformation = {};
      cluster.spec.clusterProfileTemplates.forEach((profile) => {
        profile.packs.forEach((pack) => {
          packInformation[pack.name] = pack;
        });
      });

      const promise = api
        .get(
          `v1/spectroclusters/${data.cloudConfig.hybridClusterRef.uid}/profiles?includePackMeta=schema,presets`
        )
        .then((res) =>
          res.profiles.map((profile) => ({
            ...profile,
            spec: {
              ...profile.spec,
              published: {
                packs: profile.spec.packs.map((pack) => ({
                  ...pack,
                  ...pack.spec,
                  ...packInformation[pack.metadata.name],
                  name: pack.metadata.name,
                })),
              },
            },
          }))
        );
      const response = await store.dispatch({
        type: "HYBRID_CLUSTER_PROFILES",
        promise,
        schema: [ClusterProfileSchema],
      });
      profiles = response;

      clusterConfig = await api.get(
        `v1/cloudconfigs/${data.poolCloudType}/${data.cloudConfig.hybridClusterRef.cloudConfigUid}/`
      );
    }

    return {
      ...getInitialFormDataCommon(data),
      architecture:
        data?.machinePoolProperties?.archType || ARCHITECTURE_TYPES[0].value,
      size: data?.hosts?.length || 1,
      disk: data?.diskGiB || 60,
      edgeHosts: formatHostsData(data?.cloudConfig?.edgeHosts),
      ntpServers: clusterConfig?.spec?.clusterConfig?.ntpServers || [],
      profiles: profiles.map((profile) => ({
        ...profile,
        spec: {
          ...profile.spec,
          published: {
            ...profile.spec,
          },
        },
      })),
    };
  },
  libvirt(data) {
    //TODO: remove once the backend payload is fixed
    const gpuConfig =
      data?.instanceType?.GpuConfig || data?.instanceType?.gpuConfig || {};
    const cpuPassthroughSpec = data?.instanceType?.cpuPassthroughSpec || {};

    return {
      ...getInitialFormDataCommon(data),
      disk: data?.rootDiskInGB || 60,
      cpu: data?.instanceType?.numCPUs || 2,
      memory: data?.instanceType?.memoryInMB / 1024 || 4,
      cpuset: data?.instanceType?.cpuset,
      cpuPassthroughSpec,
      gpuSupport: !!gpuConfig?.vendorName,
      gpuVendor: gpuConfig?.vendorName,
      gpuModel: gpuConfig?.deviceModel,
      numGPUs: gpuConfig?.numGPUs,
      nonRootDisks: (data?.nonRootDisksInGB || []).map((disk) => disk.sizeInGB),
      persistentNonRootDisks: !!data?.nonRootDisksInGB?.[0]?.managed,
      edgeHosts: [...cloneDeep(data?.placements || [{ hostUid: "" }])].map(
        (host) => ({
          hostUid: host.hostUid,
          dataStoragePool: host?.dataStoragePool || "",
          sourceStoragePool: host?.sourceStoragePool || "",
          targetStoragePool: host?.targetStoragePool || "",
          networkType: (host?.networks || [])[0]?.networkType || "",
          networks: (host?.networks || []).map(
            (network) => network?.networkName
          ),
          gpus: host?.gpuDevices || [],
        })
      ),
    };
  },
  tke(data) {
    let subnets = {};

    if (data?.subnetIds) {
      subnets = Object.keys(data.subnetIds).reduce((acc, key) => {
        const value = data.subnetIds[key]?.split(",");
        acc[`subnet_${key}`] = value;
        return acc;
      }, {});
    }

    return {
      ...getInitialFormDataCommon(data),
      disk: data?.rootDeviceSize || 60,
      azs: data?.azs || [],
      instanceType: data?.instanceType || "",
      ...subnets,
    };
  },
  coxedge(data) {
    const profiles = getClusterProfileTemplate(store.getState());
    const hasCalicoCNI = profiles.some((profile) => {
      return profile.spec.packs.some((pack) => {
        return pack.spec.name === "cni-calico" && pack.spec.layer === "cni";
      });
    });

    return {
      ...getInitialFormDataCommon(data),
      deployments: getDeployments(data),
      persistentStorages: data?.persistentStorages || [],
      network: {
        inboundRules: data
          ? getSecurityGroupRules("inbound", data)
          : hasCalicoCNI
          ? COX_CALICO_INBOUND_RULES
          : [],
        outboundRules: getSecurityGroupRules("outbound", data),
      },
      instanceType: Object.values(data?.spec || {}).join("") || undefined,
    };
  },
};

const replaceMacros = ({ values = "", nodeConfigMacros = {} }) => {
  let newYaml = `${values}`;
  const { optionalFields, ...fields } = nodeConfigMacros;

  function generateRegex(key) {
    return new RegExp(`\\$\\{${key}(?:=(?:"([^"]*)"|([^}]+)))?\\}`, "g");
  }
  const regexValuePair = Object.keys(fields || {}).map((key) => {
    return {
      regex: generateRegex(key),
      value: fields[key].value,
    };
  });

  regexValuePair.forEach(({ regex, value }) => {
    newYaml = newYaml.replaceAll(regex, value || `""`);
  });

  return newYaml;
};

export const addNodePoolFormActions = createFormActions({
  validator: nodePoolValidator,
  init: async (additionalData) => {
    const selectedNodePool = getSelectedNodePool(store.getState());
    const clusterCloudConfig = getClusterCloudConfig(store.getState());

    const cloudType =
      selectedNodePool?.cloudType ||
      additionalData?.cloudType ||
      clusterCloudConfig?.kind ||
      clusterCloudConfig?.metadata?.kind;

    if (clusterCloudConfig.spec.edgeHostRef?.uid) {
      await store.dispatch(vsphereAppliancesFetcher.fetch());

      if (cloudType === "vsphere") {
        store.dispatch(datacentersFetcher.fetch());
      }
    }

    store.dispatch({ type: "RESET_ESTIMATED_RATE" });
    await store.dispatch(fetchClusterCloudConfig());
    const data = (await POPULATE_FIELDS_MAPPING?.[cloudType]?.(
      selectedNodePool
    )) || {
      ...selectedNodePool,
      ...getInitialFormDataCommon(selectedNodePool),
    };

    if (!selectedNodePool && appEnv.isCustomCloud(cloudType)) {
      const metadata = getCluster(store.getState())?.metadata || {};
      const { name = "" } = metadata;
      const workerTemplate = await store.dispatch(
        cloudTemplateFetchers.worker.key(cloudType).fetch()
      );

      data.values = populateNodePoolYAML({ ...data, yaml: workerTemplate });
      data.nodeConfigMacros = getConfigMacros(data.values, name);
    }
    let extraProperties = {
      cloudType,
      withTaints: !!data?.taints?.length,
    };

    if (cloudType === "edge-native") {
      extraProperties.isTwoNode =
        clusterCloudConfig?.spec?.clusterConfig?.isTwoNodeCluster;
    }
    return Promise.resolve({
      ...data,
      ...extraProperties,
    });
  },
  submit: async (data, initialData) => {
    const state = store.getState();
    const selectedNodePool = getSelectedNodePool(state);
    const currentCluster = getCluster(state);
    const clusterCloudConfig = getClusterCloudConfig(state);
    const { metadata } = clusterCloudConfig;
    const cloudType =
      selectedNodePool?.cloudType ||
      addNodePoolModal.data?.cloudType ||
      clusterCloudConfig?.kind ||
      clusterCloudConfig?.metadata?.kind;
    const kind = mapCloudType(cloudType);
    const hasAutoscalerPack = hasAutoscalePack(state);

    let payload = PAYLOAD_MAPPING?.[cloudType]?.(data);
    if (cloudType === "eks") {
      if (!hasAutoscalerPack) {
        delete payload.poolConfig.maxSize;
        delete payload.poolConfig.minSize;
      } else {
        payload.poolConfig.maxSize = data.maxSize;
        payload.poolConfig.minSize = data.minSize;
        payload.poolConfig.size = data.minSize;
      }
    }

    const isCustomCloud = appEnv.isCustomCloud(cloudType);
    if (isCustomCloud) {
      payload = {
        ...data,
        poolConfig: {
          ...getCommonPayload(data),
        },
        cloudConfig: {
          values: replaceMacros(data),
        },
      };
    }

    let endpoint = `v1/cloudconfigs/${kind}/${metadata.uid}/machinePools`;

    if (isCustomCloud) {
      endpoint = `v1/cloudconfigs/cloudTypes/${kind}/${metadata.uid}/machinePools`;
    }

    if (cloudType === "eks-edge-native") {
      endpoint = `v1/cloudconfigs/aws/${metadata.uid}/edge-native/machinePools`;
      if (selectedNodePool) {
        const initialPayload = PAYLOAD_MAPPING?.[cloudType]?.(initialData);
        if (initialPayload) {
          if (
            !isEqual(
              initialPayload.cloudConfig.profiles,
              payload.cloudConfig.profiles
            )
          ) {
            const hybridClusterRef =
              selectedNodePool.cloudConfig.hybridClusterRef;
            api.put(`v1/spectroclusters/${hybridClusterRef.uid}/profiles`, {
              profiles: payload.cloudConfig.profiles,
            });
          }
        }
      }
    }

    const promise = selectedNodePool
      ? api.put(`${endpoint}/${selectedNodePool.name}`, payload)
      : api.post(endpoint, payload);

    try {
      await promise;
    } catch (error) {
      const message = selectedNodePool
        ? i18n.t("Something went wrong when editing the node pool")
        : i18n.t("Something went wrong when creating the node pool");

      notifications.error({
        message,
        description: error.message,
      });

      return Promise.reject();
    }

    const editMessage = i18next.t(
      "Node pool with name '{{poolName}}' was updated successfully. Cluster update will begin shortly",
      { poolName: data.poolName }
    );

    const createMessage = i18next.t(
      "Node pool with name '{{poolName}}' was created successfully. Cluster update will begin shortly",
      { poolName: data.poolName }
    );

    notifications.success({
      message: selectedNodePool ? editMessage : createMessage,
    });

    await store.dispatch(getClusterByUid(currentCluster.metadata.uid));
    await store.dispatch(fetchClusterCloudConfig());
    store.dispatch(fetchClusterEstimatedRate());
    pollNodes.start();
  },
});

export const tencentNodesForm = createTencentFormFactory(
  {
    formModuleName: "nodePool",
    formActions: addNodePoolFormActions,
    getCloudAccountUid(state) {
      return getClusterCloudConfig(state)?.spec?.cloudAccountRef?.uid;
    },
    getClusterConfig(state) {
      const config = getClusterCloudConfig(state)?.spec?.clusterConfig;
      const nodePoolFormData = state.forms?.nodePool?.data || {};
      return {
        ...nodePoolFormData,
        region: config?.region,
        vpcid: config?.vpcID,
      };
    },
  },
  { isNodes: true }
);

// aws params are fetched inside form init
function loadNodePoolCloudProperties() {
  return async (dispatch, getState) => {
    const state = getState();
    const cloudConfig = getClusterCloudConfig(getState());
    const selectedPool = getSelectedNodePool(state);
    const kind =
      selectedPool?.poolCloudType ||
      addNodePoolModal.data?.cloudType ||
      cloudConfig.kind ||
      cloudConfig.metadata.kind;

    if (["aks", "azure"].includes(kind)) {
      dispatch(fetchAzureNodeParams());
    }

    if (["gcp", "gke"].includes(kind)) {
      dispatch(fetchGoogleCloudNodeParams());
    }

    if (kind === "vsphere") {
      dispatch(dnsMappingsFetcher.fetch());
      dispatch(fetchVmWareNodeParams());
    }

    if (kind === "maas") {
      dispatch(fetchMaasCloudNodeParams(maasNodesForm));
    }

    if (kind === "openstack") {
      return openstackNodesForm.effects.fetchProperties();
    }

    if (kind === "tke") {
      return dispatch(tencentNodesForm.effects.fetchNodesConfigParams());
    }

    if (kind === "coxedge") {
      const region = state.forms.nodePool?.data?.deployments?.[0].pops;
      dispatch(fetchNodesConfigParams({ region }));
    }

    if (BAREMETAL_ENVS.includes(kind)) {
      dispatch(fetchBaremetalParams(true));
    }
  };
}

export function openAddNodePoolModal({
  type = "",
  nodePoolGuid,
  cloudType,
} = {}) {
  return async (dispatch) => {
    addNodePoolModal.open({ type, nodePoolGuid, cloudType }).then(
      () => dispatch(addNodePoolFormActions.submit({ module: "nodePool" })),
      () => {
        dispatch(setNodePoolToConfigure());
      }
    );

    await dispatch(
      addNodePoolFormActions.init({
        module: "nodePool",
        cloudType,
      })
    );
    dispatch(loadNodePoolCloudProperties());
  };
}

export const deleteNodeConfirm = new ModalService("deleteNode");

export function deleteNode(node) {
  return async (dispatch, getState) => {
    const state = store.getState();
    const currentCluster = getCluster(state);
    const currentCloudConfig = getClusterCloudConfig(state);
    const kind = currentCloudConfig?.kind || currentCloudConfig?.metadata?.kind;
    const clusterKind = kind === "all" ? "generic" : mapCloudType(kind);
    const poolType = node?.metadata?.annotations?.machinePoolType;
    const poolTypeNodes = (getAllNodes(state) || []).filter(
      (node) => node?.metadata?.annotations?.machinePoolType === poolType
    );

    if (poolTypeNodes.length < 2 && kind !== "edge-native") {
      notifications.error({
        message: i18n.t(
          "Cannot delete the only Node available. Create another Node in order to delete the current one."
        ),
      });
      return;
    }

    const machinePoolName = node?.metadata?.annotations?.machinePool;
    const machineUid = node?.metadata?.uid;

    deleteNodeConfirm.open().then(async () => {
      const promise = api.delete(
        `v1/cloudconfigs/${clusterKind}/${currentCloudConfig?.metadata?.uid}/machinePools/${machinePoolName}/machines/${machineUid}`
      );
      try {
        await promise;
      } catch (error) {
        notifications.error({
          message: i18n.t("Something went wrong when deleting the node"),
          description: error.message,
        });
        return;
      }
      notifications.success({
        message: i18next.t(
          "Node '{{nodeName}}' has been deleted successfully. Cluster update will begin shortly",
          { nodeName: node?.metadata?.name }
        ),
      });
      await store.dispatch(fetchClusterNodes());
      await store.dispatch(getClusterByUid(currentCluster.metadata.uid));
      await store.dispatch(fetchClusterCloudConfig());
      store.dispatch(fetchClusterEstimatedRate());
    });
  };
}

export const deleteNodePoolConfirm = new ModalService("deleteNodePool");

export function deleteNodePool(nodePoolName) {
  return async (dispatch, getState) => {
    const state = store.getState();
    const currentCluster = getCluster(state);
    const currentCloudConfig = getClusterCloudConfig(state);
    const nodePools = getNodePools(state);
    const nodepool = nodePools.find(
      (nodePool) => nodePool.name === nodePoolName
    );
    const cloudType =
      currentCloudConfig?.kind || currentCloudConfig?.metadata?.kind;
    const kind = mapCloudType(cloudType);
    const isHybrid = kind === "aws" && nodepool.poolCloudType === "edge-native";
    const nodePoolsWithoutTaints = (nodePools || []).filter(
      (nodePool) => !(nodePool.taints || []).length
    );

    if (
      !isHybrid &&
      nodePoolsWithoutTaints.length === 1 &&
      nodePoolsWithoutTaints[0].name === nodePoolName
    ) {
      notifications.error({
        message: i18n.t(
          "Cannot delete the only node pool without taints. Create another node pool in order to delete the current one."
        ),
      });
      return;
    }

    if (kind === "aks") {
      const systemNodePools = getSystemNodePools(state);
      const isSystemNodePool = systemNodePools.find(
        (nodePool) => nodePool?.name === nodePoolName
      );

      if (isSystemNodePool && systemNodePools.length < 2) {
        notifications.error({
          message: i18n.t(
            "Cannot delete the only System Node Pool available. Create another System Node Pool in order to delete the current one."
          ),
        });
        return;
      }
    }

    deleteNodePoolConfirm.open().then(async () => {
      const isCustomCloud = appEnv.isCustomCloud(kind);
      let endpoint = `v1/cloudconfigs/${kind}/${currentCloudConfig.metadata.uid}/machinePools/${nodePoolName}`;
      if (isCustomCloud) {
        endpoint = `v1/cloudconfigs/cloudTypes/${kind}/${currentCloudConfig.metadata.uid}/machinePools/${nodePoolName}`;
      }

      if (isHybrid) {
        endpoint = `v1/cloudconfigs/aws/${currentCloudConfig.metadata.uid}/edge-native/machinePools/${nodePoolName}`;
      }

      const promise = api.delete(endpoint);

      dispatch({
        type: "DELETE_NODE_POOL",
        promise,
      });

      try {
        await promise;
      } catch (error) {
        notifications.error({
          message: i18n.t("Something went wrong when deleting the node pool"),
          description: error.message,
        });

        return;
      }

      notifications.success({
        message: i18next.t(
          "Node pool with name '{{nodePoolName}}' has been deleted successfully. Cluster update will begin shortly",
          { nodePoolName }
        ),
      });

      await store.dispatch(getClusterByUid(currentCluster.metadata.uid));
      await store.dispatch(fetchClusterCloudConfig());
      store.dispatch(fetchClusterEstimatedRate());
    });
  };
}

export function onDataclusterChange(name, cluster) {
  return (dispatch, getState) => {
    const pathParts = name.split(".");
    const domainIndex = pathParts[1];
    const domains = [...getState().forms.nodePool.data.domains];

    const prevValue = domains[domainIndex].cluster;

    domains.splice(domainIndex, 1, {
      cluster,
      datastore: "",
      network: "",
      resourcePool: "",
      staticIp: domains[domainIndex].staticIp,
    });
    dispatch(
      addNodePoolFormActions.batchChange({
        module: "nodePool",
        updates: {
          domains,
        },
      })
    );

    dispatch(propertiesFetcher.key(cluster).fetch());

    if (prevValue !== "") {
      const relatedErrors = domains.reduce((accumulator, domain, index) => {
        if (domain.cluster === prevValue && index !== domainIndex) {
          accumulator.push(index);
        }

        return accumulator;
      }, []);

      dispatch(
        addNodePoolFormActions.validateField({
          name: relatedErrors.map((index) => `domains.${index}.cluster`),
          module: "nodePool",
        })
      );
    }
  };
}

export function onNetworkChange(name, network) {
  return (dispatch, getState) => {
    const pathParts = name.split(".");
    const domainIndex = pathParts[1];
    const domains = [...getState().forms.nodePool.data.domains];
    domains.splice(domainIndex, 1, {
      ...domains[domainIndex],
      network,
    });
    dispatch(
      addNodePoolFormActions.batchChange({
        module: "nodePool",
        updates: {
          domains,
        },
      })
    );

    const relatedErrors = domains.reduce((accumulator, domain, index) => {
      if (domain.network === network && index !== domainIndex) {
        accumulator.push(index);
      }

      return accumulator;
    }, []);

    dispatch(
      addNodePoolFormActions.validateField({
        name: relatedErrors.map((index) => `domains.${index}.network`),
        module: "nodePool",
      })
    );
  };
}

export function onPoolChange(name, value) {
  return (dispatch, getState) => {
    const pathParts = name.split(".");
    const domainIndex = pathParts[1];
    const domains = [...getState().forms.nodePool.data.domains];
    domains[domainIndex] = {
      ...domains[domainIndex],
      parentPoolUid: value,
    };

    dispatch(
      addNodePoolFormActions.batchChange({
        module: "nodePool",
        updates: {
          domains,
        },
      })
    );
  };
}

export function onAddDomain(name) {
  return (dispatch, getState) => {
    const domains = [...getState().forms.nodePool.data.domains];
    domains.push({
      cluster: "",
      datastore: "",
      network: "",
      resourcePool: "",
      parentPoolUid: "",
    });

    dispatch(
      addNodePoolFormActions.batchChange({
        module: "nodePool",
        updates: {
          domains,
        },
      })
    );
  };
}

export function onDeleteDomain(name) {
  return (dispatch, getState) => {
    const pathParts = name.split(".");
    const domainIndex = pathParts[1];
    const domains = [...getState().forms.nodePool.data.domains];
    domains.splice(domainIndex, 1);

    const errorFields = ["cluster", "datastore", "network"].map(
      (field) => `domains.${domainIndex}.${field}`
    );
    const formErrors = getState().forms.nodePool.errors;
    const updatedErrors = formErrors.map((error) => {
      const shouldRemove = errorFields.includes(error.field);
      if (shouldRemove) {
        return { ...error, result: false };
      }

      return error;
    });

    dispatch(
      addNodePoolFormActions.updateErrors({
        module: "nodePool",
        errors: updatedErrors,
      })
    );

    dispatch(
      addNodePoolFormActions.batchChange({
        module: "nodePool",
        updates: {
          domains,
        },
      })
    );
  };
}

export function onInstanceTypeChange(instanceType) {
  return (dispatch, getState) => {
    const state = getState();
    const initialAzs = state.forms?.nodePool?.initialData?.azs || [];
    const cluster = getCluster(state);
    const { cloudType } = cluster.spec;
    const instanceTypes =
      state.cluster?.details?.cloudConfigParams?.instanceTypes || [];

    dispatch(
      addNodePoolFormActions.batchChange({
        module: "nodePool",
        updates: {
          instanceType,
          azs: initialAzs,
          instancePrice:
            instanceTypes.find(({ type }) => type === instanceType)?.price || 0,
        },
      })
    );

    if (cloudType === "azure") {
      const selectedNodePool = getSelectedNodePool(state);
      const instanceTypes = getAzureInstanceTypes(
        selectedNodePool?.isControlPlane
      )(state);

      const selectedInstanceType = instanceTypes
        .map((types) => types.children)
        .flat()
        .find((instance) => instance.title === instanceType);

      const nonSupportedZones =
        selectedInstanceType.description.props?.nonSupportedZones || [];

      if (nonSupportedZones) {
        const azs = initialAzs.filter((az) => !nonSupportedZones?.includes(az));

        dispatch(
          addNodePoolFormActions.onChange({
            module: "nodePool",
            name: "azs",
            value: azs,
          })
        );
      }
    }
  };
}

export function onInstanceOptionChange(instanceOption) {
  return (dispatch) => {
    dispatch(
      addNodePoolFormActions.onChange({
        module: "nodePool",
        name: "instanceOption",
        value: instanceOption,
      })
    );
  };
}

export function onAMIChange(value) {
  return (dispatch, getState) => {
    dispatch(
      addNodePoolFormActions.batchChange({
        module: "nodePool",
        updates: {
          amiId: value,
        },
      })
    );

    dispatch(
      addNodePoolFormActions.clearErrors({
        module: "nodePool",
        field: "disk",
      })
    );
  };
}

export function onLaunchTemplateChange(value) {
  return (dispatch, getState) => {
    const nodePool = getState().forms?.nodePool?.data;
    dispatch(
      addNodePoolFormActions.batchChange({
        module: "nodePool",
        updates: {
          launchTemplateEnabled: value,
          amiId: nodePool.amiId || "",
          rootVolume: nodePool.rootVolume || {
            type: "",
            iops: "",
            throughput: "",
          },
        },
      })
    );
  };
}

export function onRootVolumeTypeChange(value) {
  return (dispatch, getState) => {
    const nodePool = getState().forms?.nodePool?.data;
    dispatch(
      addNodePoolFormActions.batchChange({
        module: "nodePool",
        updates: {
          rootVolume: {
            ...nodePool.rootVolume,
            type: value,
          },
        },
      })
    );
    dispatch(
      addNodePoolFormActions.clearFieldErrors({
        module: "nodePool",
        field: "rootVolume.throughput",
      })
    );
  };
}

export function onRootVolumeIOPSChange(value) {
  return (dispatch, getState) => {
    const nodePool = getState().forms?.nodePool?.data;
    dispatch(
      addNodePoolFormActions.batchChange({
        module: "nodePool",
        updates: {
          rootVolume: {
            ...nodePool.rootVolume,
            iops: value,
          },
        },
      })
    );
    dispatch(
      addNodePoolFormActions.clearFieldErrors({
        module: "nodePool",
        field: "rootVolume.throughput",
      })
    );
  };
}

export function onAzsChange(value) {
  return (dispatch) => {
    dispatch(
      addNodePoolFormActions.onChange({
        module: "nodePool",
        name: "azs",
        value,
      })
    );
  };
}

export const debouncedNodePoolChange = debounce(nodePoolChange, 2000);

function nodePoolChange(data) {
  return async (dispatch) => {
    if (!data) return;

    let errors = [];
    const validations = nodePoolValidator.run(data);

    for await (const error of validations) {
      if (error.result) {
        errors.push(error);
      }
    }

    if (!errors?.length) {
      dispatch(fetchNodePoolEstimatedRate(data));
    } else {
      dispatch({ type: "FETCH_NODE_ESTIMATED_RATE_FAILURE" });
    }
  };
}

export function removeTaint(index) {
  return (dispatch, getState) => {
    const taints = [...getState().forms?.nodePool?.data?.taints];
    taints.splice(index, 1);

    dispatch(
      addNodePoolFormActions.onChange({
        module: "nodePool",
        name: "taints",
        value: taints,
      })
    );

    const errors = getState().forms.nodePool.errors;
    const errorRemnants = errors.map((error) =>
      error?.field.includes(`taints`) ? { ...error, result: null } : error
    );

    dispatch(
      addNodePoolFormActions.updateErrors({
        module: "nodePool",
        errors: errorRemnants,
      })
    );
  };
}

export function addNewTaint() {
  return (dispatch, getState) => {
    const taints = [...getState().forms?.nodePool?.data?.taints];
    taints.push({ key: "", value: "", effect: null });

    dispatch(
      addNodePoolFormActions.onChange({
        module: "nodePool",
        name: "taints",
        value: taints,
      })
    );
  };
}

function estimateRatePerNodePool(nodePoolGuid, desiredSize) {
  return async (dispatch, getState) => {
    store.dispatch({ type: "RESET_ESTIMATED_RATE" });

    const state = getState();
    const nodePool = getNodePools(state).find(
      (nodePool) => nodePool.guid === nodePoolGuid
    );
    const clusterCloudConfig = getClusterCloudConfig(state);
    const cloudType =
      clusterCloudConfig?.kind || clusterCloudConfig?.metadata?.kind;

    const isCustomCloud = appEnv.isCustomCloud(cloudType);
    if (!isCustomCloud) {
      const formData = await POPULATE_FIELDS_MAPPING[cloudType](nodePool);
      return dispatch(
        fetchNodePoolEstimatedRate({ ...formData, size: desiredSize })
      );
    }
  };
}

function fetchNodePoolEstimatedRate(data) {
  return async (dispatch, getState) => {
    const clusterCloudConfig = getClusterCloudConfig(getState());
    const selectedNodePool = getSelectedNodePool(getState());
    const kind =
      selectedNodePool?.poolCloudType ||
      addNodePoolModal.data?.cloudType ||
      clusterCloudConfig?.kind ||
      clusterCloudConfig?.metadata?.kind;

    if (kind === "eks-edge-native") {
      return Promise.resolve();
    }
    if (!["vsphere", "edge-native"].includes(kind)) {
      await dispatch(loadNodePoolCloudProperties());
    }
    const payload = PAYLOAD_MAPPING[kind](data);

    let cloudConfig = clusterCloudConfig?.spec?.clusterConfig;

    if (["azure", "aks"].includes(kind)) {
      cloudConfig.aadProfile = {
        ...cloudConfig?.aadProfile,
        adminGroupObjectIDs: cloudConfig?.aadProfile?.adminGroupObjectIDs || [],
      };
    }

    if (kind === "libvirt") {
      payload.cloudConfig = {
        ...payload.cloudConfig,
        placements: (payload?.cloudConfig?.placements || []).filter(
          ({ hostUid }) => !!hostUid
        ),
      };
    }

    if (["edge", "edge-native"].includes(kind)) {
      payload.cloudConfig = {
        ...payload.cloudConfig,
        edgeHosts: (payload?.cloudConfig?.edgeHosts || []).filter(
          ({ hostUid }) => !!hostUid
        ),
      };
    }

    if (["azure", "aks"].includes(kind)) {
      cloudConfig = {
        ...cloudConfig,
        infraLBConfig: {
          ...(cloudConfig?.infraLBConfig || {}),
          apiServerLB: {
            ...(cloudConfig?.infraLBConfig.apiServerLB || {}),
            ipAllocationMethod: "Dynamic",
            type: "Public",
          },
        },
      };
    }

    const ratePayload = {
      cloudConfig,
      machinepoolconfig: [
        {
          cloudConfig: payload.cloudConfig,
          poolConfig: payload.poolConfig,
        },
      ],
    };

    const promise = api.post(
      `v1/spectroclusters/${kind}/rate?periodType=hourly`,
      ratePayload
    );

    dispatch({
      type: "FETCH_NODE_ESTIMATED_RATE",
      promise,
    });

    try {
      await promise;
    } catch (error) {
      notifications.error({
        message: i18n.t("Something went wrong"),
        description: error.message,
      });
      return;
    }

    return promise;
  };
}

export function fetchClusterEstimatedRate() {
  return (dispatch, getState) => {
    const cluster = getCluster(getState());
    const uid = cluster?.metadata?.uid;

    if (!uid) {
      return;
    }

    const promise = api.get(`v1/spectroclusters/${uid}/rate?periodType=hourly`);

    dispatch({
      type: "NODES_FETCH_RATES",
      promise,
    });
  };
}

export function onEditApplianceChange(deviceIndex, value) {
  return async (dispatch, getState) => {
    const state = getState();
    const formData = state.forms.nodePool.data;
    const initialFormData = state.forms.nodePool.initialData;
    const edgeHosts = [...cloneDeep(formData.edgeHosts)];
    const edgeHostToChange = edgeHosts[deviceIndex];
    edgeHostToChange.hostUid = value || "";

    dispatch(
      addNodePoolFormActions.onChange({
        module: "nodePool",
        name: "edgeHosts",
        value: edgeHosts,
      })
    );

    await dispatch(edgeHostDetailsFetcher.key(value).fetch());

    const cluster = getCluster(state);

    const nicsByDevice = getNicsByDeviceUid(state);
    const currentEdgeHostNics = nicsByDevice[value] || [];

    if (cluster.spec.cloudType === "edge-native") {
      const matchingInitialEdgeHost = initialFormData?.edgeHosts?.find(
        ({ hostUid }) => hostUid === value
      );
      const defaultNic =
        currentEdgeHostNics.find((nic) => nic?.isDefault) ||
        currentEdgeHostNics[0];

      edgeHostToChange.nicName =
        matchingInitialEdgeHost?.nicName || defaultNic?.nicName || "";

      edgeHostToChange.ip = defaultNic?.ip;
      edgeHostToChange.gateway = defaultNic?.gateway || "";
      edgeHostToChange.subnet = defaultNic?.subnet || "";
      edgeHostToChange.dns = defaultNic?.dns || [];
    }

    if (cluster.spec.cloudType === "libvirt") {
      edgeHostToChange.networks = [];
      edgeHostToChange.networkType = "";
      edgeHostToChange.dataStoragePool = "";
      edgeHostToChange.sourceStoragePool = "";
      edgeHostToChange.targetStoragePool = "";
      dispatch(
        addNodePoolFormActions.batchChange({
          module: "nodePool",
          updates: { edgeHosts, gpuVendor: "", gpuModel: "" },
        })
      );
    }

    dispatch(nodePoolAppliancesFetcher.fetch());
    nodePoolApplianceResourceFetchers.forEach((fetcher) =>
      dispatch(fetcher.key(`${deviceIndex}`).fetch())
    );
  };
}

export function nodePoolAddNewVirtualDevice() {
  return (dispatch, getState) => {
    const edgeHosts = [...getState().forms?.nodePool?.data?.edgeHosts];
    edgeHosts.push({ hostUid: "" });
    dispatch(nodePoolAppliancesFetcher.fetch());

    dispatch(
      addNodePoolFormActions.onChange({
        module: "nodePool",
        name: "edgeHosts",
        value: edgeHosts,
      })
    );
  };
}

export function removeNodePoolVirtualDevice(deviceIndex) {
  return (dispatch, getState) => {
    const edgeHosts = [...getState().forms?.nodePool?.data?.edgeHosts];
    edgeHosts.splice(deviceIndex, 1);

    dispatch(
      addNodePoolFormActions.onChange({
        module: "nodePool",
        name: "edgeHosts",
        value: edgeHosts,
      })
    );

    nodePoolApplianceResourceFetchers.forEach((fetcher) =>
      dispatch(fetcher.key(`${deviceIndex}`).fetch())
    );
    dispatch(nodePoolAppliancesFetcher.fetch());
  };
}

export function onNodePoolArchitectureChange(value) {
  return (dispatch) => {
    dispatch(
      addNodePoolFormActions.onChange({
        module: "nodePool",
        name: "architecture",
        value,
      })
    );

    dispatch(
      addNodePoolFormActions.onChange({
        module: "nodePool",
        name: "edgeHosts",
        value: [],
      })
    );
  };
}

export function onNodePoolNetworkTypeChange(deviceIndex, value) {
  return (dispatch, getState) => {
    const edgeHosts = [...cloneDeep(getState().forms.nodePool.data.edgeHosts)];
    edgeHosts[deviceIndex].networkType = value;
    edgeHosts[deviceIndex].networks = [];

    dispatch(
      addNodePoolFormActions.onChange({
        module: "nodePool",
        name: "edgeHosts",
        value: edgeHosts,
      })
    );

    dispatch(
      nodePoolApplianceNetworksKeyedFetcher.key(`${deviceIndex}`).fetch()
    );
  };
}

export function onEdgeHostUpdate(edgeHosts) {
  return function thunk(dispatch) {
    dispatch(
      addNodePoolFormActions.onChange({
        module: "nodePool",
        name: "edgeHosts",
        value: edgeHosts,
      })
    );

    dispatch(
      addNodePoolFormActions.validateField({
        module: "nodePool",
        name: "edgeHosts",
      })
    );
  };
}

export const hybridUpdatesModals = {
  diffModal: new ModalService("hybrid-profile-diff"),
  reviewModal: new ModalService("hybrid-profile-diff-review"),
};

function presentPacks(profiles) {
  return profiles
    .filter((profile) => profile.type !== "system")
    .reduce(
      (acc, profile) => [
        ...acc,
        ...profile.spec.published.packs.map((pack) => ({
          ...pack,
          profile: profile,
          manifests: [],
        })),
      ],
      []
    );
}
// duplicated logic from profileDifferentiator.getPackChanges
function extractProfilePackChanges({ targetProfiles, currentProfiles }) {
  const currentPacks = presentPacks(currentProfiles);
  const targetPacks = presentPacks(targetProfiles);
  const changes = [];

  const filteredTargetPacks = targetPacks.filter((targetPack) => {
    const existingPack = currentPacks.find((initialPack) => {
      return (
        initialPack?.spec?.name === targetPack?.spec?.name &&
        initialPack.profile.metadata.uid === targetPack.profile.metadata.uid
      );
    });

    return !existingPack;
  });

  filteredTargetPacks?.forEach((targetPack) => {
    const currentPack = currentPacks.find(
      (initialPack) => initialPack?.spec?.name === targetPack?.spec?.name
    );

    const targetPackValues = targetPack?.spec?.values;
    if (!currentPack) {
      changes.push({
        type: "pack-added",
        current: null,
        target: {
          pack: targetPack.guid,
          values: targetPackValues,
          profile: targetPack.profileGuid,
        },
        manifests: targetPack.manifests.map((manifest) => ({
          type: "manifest-added",
          current: null,
          target: {
            pack: targetPack.guid,
            manifest: manifest.guid,
            values: manifest?.content,
          },
        })),
      });
    }

    if (currentPack) {
      const packChanges = [];
      const currentManifests = currentPack?.manifests || [];
      const targetManifests = targetPack?.manifests || [];
      const manifestChanges = [];

      targetManifests.forEach((targetManifest) => {
        const currentManifest = currentManifests.find(
          (initialManifest) => initialManifest.name === targetManifest.name
        );
        const targetManifestValues = targetManifest?.content;

        if (!currentManifest) {
          manifestChanges.push({
            type: "manifest-added",
            current: null,
            target: {
              pack: targetPack,
              manifest: targetManifest,
              values: targetManifest?.content,
            },
          });
        }

        if (currentManifest) {
          const currentManifestValues = currentManifest?.content;

          if (currentManifestValues !== targetManifestValues) {
            manifestChanges.push({
              type: "manifest-updated",
              current: {
                pack: currentPack,
                manifest: currentManifest,
                values: currentManifestValues,
                profile: currentPack.profile,
              },
              target: {
                pack: targetPack,
                manifest: targetManifest,
                values: targetManifestValues,
                profile: targetPack.profile,
              },
            });
          }

          // unknown change state
          if (
            (!currentManifestValues || !targetManifestValues) &&
            currentManifest?.guid !== targetManifest?.guid
          ) {
            manifestChanges.push({
              type: "unknown",
              current: {
                pack: currentPack,
                manifest: currentManifest,
                profile: currentPack.profile,
                manifestName: currentManifest?.name,
              },
              target: {
                pack: targetPack,
                manifest: targetManifest,
                profile: targetPack.profile,
                manifestName: targetManifest?.name,
              },
            });
          }
        }
      });

      currentManifests.forEach((currentManifest) => {
        const targetManifest = targetManifests.find(
          (targetManifest) => targetManifest.name === currentManifest.name
        );

        if (!targetManifest) {
          manifestChanges.push({
            type: "manifest-removed",
            current: {
              pack: currentPack,
              manifest: currentManifest,
              profile: currentPack.profile,
            },
            target: null,
          });
        }
      });

      if (
        targetPackValues?.trim() !==
        getPackValuesWithoutPresetsComment(currentPack?.spec?.values)?.trim()
      ) {
        packChanges.push("values");
      }

      if (targetPack?.spec?.version !== currentPack?.spec?.version) {
        packChanges.push("version");
      }

      if (targetPack?.spec?.isInfoUpdate) {
        packChanges.push("info");
      }

      if (manifestChanges.length) {
        packChanges.push("children");
      }

      if (packChanges.length) {
        changes.push({
          type: "pack-updated",
          current: {
            pack: currentPack,
            values: currentPack.spec.values,
            profile: currentPack.profile,
          },
          target: {
            pack: targetPack,
            values: targetPackValues,
            profile: targetPack.profile,
          },
          manifests: manifestChanges,
          changes: packChanges,
        });
      }
    }
  });

  currentPacks?.forEach((currentPack) => {
    const targetPack = targetPacks.find(
      (initialPack) => initialPack?.spec?.name === currentPack?.spec?.name
    );

    if (!targetPack) {
      changes.push({
        type: "pack-removed",
        current: {
          pack: currentPack,
          values: currentPack.spec.values,
          profile: currentPack.profile,
        },
        target: null,
        manifests: currentPack.manifests.map((manifest) => ({
          type: "manifest-removed",
          current: {
            manifest: manifest,
            pack: currentPack,
            profile: currentPack.profile,
          },
          target: null,
        })),
      });
    }
  });

  return changes;
}

function submitHybridUpdates(pools, values) {
  const updatePromises = pools.map(async ({ changes, notifications }) => {
    const updates = changes.map(({ profile, packs }) => {
      return {
        profile,
        packs: packs
          .filter((packChange) => !!packChange.target)
          .map((packChange) => {
            return {
              ...packChange,
              manifests: packChange.manifests.filter(
                (manifestChange) => !!manifestChange.target
              ),
            };
          }),
      };
    });
    const payload = updates.map(({ profile, packs }) => ({
      uid: profile.metadata.uid.replace("-incoming", ""),
      packs: packs.map(({ target, manifests }) => ({
        name: target.pack.name,
        values: values[target.pack.guid],
        version: target.pack.version,
        tag: target.pack.tag,
        manifests: manifests.map(({ target }) => ({
          name: target.manifest.name,
          content: values[target.manifest.guid],
        })),
      })),
    }));
    const notification = notifications[0];
    await api.patch(`v1/${notification.action.link}`, {
      profiles: payload,
    });
    api.patch(`v1/notifications/${notification.metadata.uid}/ack`);
  });

  const updates = Promise.allSettled(updatePromises);

  return updates;
}

export const hybridUpdateFormActions = createFormActions({
  async init() {
    const data = hybridUpdatesModals.reviewModal.data;
    return data.reduce((acc, { diffs }) => {
      diffs.forEach((diff) => {
        diff.packs.forEach((change) => {
          acc[change.target.pack.guid] = change.target.values;
        });
      });
      return acc;
    }, {});
  },
  submit(values) {
    return submitHybridUpdates(hybridUpdatesModals.reviewModal.data, values);
  },
});

export function openHybridDiffModal() {
  return async function thunk(dispatch, getState) {
    const cluster = getCluster(getState());
    const hybridPools = cluster.spec.cloudConfig.spec.hybridMachinePools || [];
    const modalData = [];
    const promises = hybridPools.map(async (pool) => {
      const cluster = pool?.cloudConfig?.hybridCluster;
      const actionableNotifications = (cluster.notifications || []).filter(
        (notification) =>
          notification.action.ack === false &&
          Object.keys(notification.events).length > 0
      );

      if (actionableNotifications.length === 0) {
        return;
      }

      const profiles = await dispatch({
        type: "FETCH_HYBRID_PROFILES",
        promise: api
          .get(`v1/spectroclusters/${cluster.metadata.uid}/profiles`)
          .then((res) =>
            res.profiles.map((profile) => ({
              ...profile,
              packs: profile.spec.packs.map((pack) => ({
                ...pack,
                ...pack.metadata,
                ...pack.spec,
              })),
              guid: `${cluster.metadata.uid}-${profile.metadata.uid}`,
            }))
          ),
        schema: [ClusterProfileTemplateSchema],
      });

      const listOfPacks = new Set();
      const packDiffs = {};
      const incomingValues = {};
      const eventDataPromises = actionableNotifications
        .flatMap((notification) => notification.events)
        .map(async (event) => {
          if (
            UPDATE_TYPES.includes(event.type) &&
            !listOfPacks.has(event.packName)
          ) {
            const diffs = await dispatch({
              promise: api
                .get(
                  `v1/spectroclusters/${cluster.metadata.uid}/packs/${event.packName}/config`
                )
                .then((res) => res.items),
              type: "FETCH_HYBRID_PACK_DIFFS",
              schema: [
                {
                  spec: PackVersionSchema,
                },
              ],
            });
            const current =
              diffs.find((diff) => diff.spec.scope === "spectrocluster")
                ?.spec || null;
            const target =
              diffs.find((diff) => diff.spec.scope === "clusterprofile")
                ?.spec || null;

            packDiffs[event.profileUid] = packDiffs[event.profileUid] || {};
            packDiffs[event.profileUid][event.packName] = { target, current };
            incomingValues[target.guid] = target.values;
            listOfPacks.add(event.packName);
          }
        }, []);

      await Promise.allSettled(eventDataPromises);
      const targetProfiles = buildTargetProfiles({
        profiles: profiles.map((profile) => ({
          ...profile,
          packs: (profile.packs || []).map((pack) => ({
            ...pack,
            ...pack.metadata,
            ...pack.spec,
          })),
        })),
        incomingUpdates: actionableNotifications.flatMap(
          (notification) => notification.events
        ),
        packDiffs,
      }).map((profile) => {
        return {
          ...profile,
          metadata: {
            ...profile.metadata,
            uid: `${profile.metadata.uid}-incoming`,
          },
          spec: {
            ...profile.spec,
            published: {
              ...profile.spec.published,
              packs: profile.spec.published.packs.map((pack) => ({
                ...pack,
                guid: `${cluster.metadata.uid}-${profile.metadata.uid}-${pack.guid}`,
              })),
            },
          },
          guid: `${cluster.metadata.uid}-${profile.metadata.uid}-incoming`,
        };
      });

      const changes = extractProfilePackChanges({
        targetProfiles,
        currentProfiles: profiles.map((profile) => ({
          ...profile,
          spec: {
            ...profile.spec,
            published: {
              packs: profile.packs,
            },
          },
        })),
      });

      // duplicated logic from profileDifferentiator
      function updatesOnly() {
        const filteredChanges = changes
          .filter(
            (packChange) =>
              packChange.type === "pack-updated" &&
              (packChange?.changes.includes("values") ||
                packChange?.manifests.some(
                  (manifestChange) => manifestChange.type === "manifest-updated"
                ))
          )
          .map((packChange) => ({
            ...packChange,
            manifests: packChange.manifests.filter(
              (manifestChange) => manifestChange.type === "manifest-updated"
            ),
          }));

        return profileDifferentiator.groupChange(filteredChanges);
      }
      function updatesAndAdditionsOnly() {
        const filteredChanges = changes
          .filter(
            (packChange) =>
              packChange.type === "pack-added" ||
              (packChange.type === "pack-updated" &&
                (packChange?.changes.includes("values") ||
                  packChange?.manifests.some((manifestChange) =>
                    ["manifest-updated", "manifest-added"].includes(
                      manifestChange.type
                    )
                  )))
          )
          .map((packChange) => ({
            ...packChange,
            manifests: packChange.manifests.filter((manifestChange) =>
              ["manifest-updated", "manifest-added"].includes(
                manifestChange.type
              )
            ),
          }));

        return profileDifferentiator.groupChange(filteredChanges);
      }

      modalData.push({
        pool,
        cluster,
        profiles,
        notifications: actionableNotifications,
        diffs: updatesAndAdditionsOnly(),
        updates: updatesOnly(),
        changes: profileDifferentiator.groupChange(changes),
      });
    });

    await Promise.allSettled(promises);
    hybridUpdatesModals.diffModal.open(modalData).then(async (selected) => {
      const selectedPools = modalData.filter((update) =>
        selected.includes(update.cluster.metadata.uid)
      );

      const hasChangesToReview = selectedPools.some(
        (update) => update.updates.length
      );

      if (!hasChangesToReview) {
        await submitHybridUpdates(selectedPools, {});
        dispatch(fetchClusterCloudConfig());
        return;
      }

      hybridUpdatesModals.reviewModal.open(selectedPools).then(async () => {
        const payloadPushAction = dispatch(
          hybridUpdateFormActions.submit({
            module: "hybrid-profile-diff-review",
          })
        );

        await payloadPushAction;

        dispatch(fetchClusterCloudConfig());

        return payloadPushAction;
      });
      dispatch(
        hybridUpdateFormActions.init({ module: "hybrid-profile-diff-review" })
      );
    });
  };
}
